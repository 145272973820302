import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { IManagementUserForm } from "../../../../../../redux/reducers/management/user/form";
import { compileNameOfProperty, convertToLocal, mapIDescriptionToDropDownOptions } from "../../../../../../utils";
import { FormField } from "../../../../../common/fields/fields";
import { ManagementUserDefaultFormStyle } from "../../baseFormStyle.jss";
import { FormatDate } from "../../../../../../common/enum/dateTime/FormatDate";
import { LabelText } from "../../../../../common/labels/labelText/labelText";
import { useEffect } from "react";
import { myProfileResolver } from "../../default/resolver";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { fetchOrganisms } from "../../../../../../redux/actions/catalog/catalog";
import { Selector } from "../../../../../common/selectors/selector";

interface IManagementUserOrganismAdminFormDataProps {
    generalUserData: IManagementUserForm;
    setIsValidTab: (value: boolean) => any;
    onSelectorChange: (event: any, option: any, name: string) => any;
    onInputChange: (event: any) => any;
    editMode: boolean;
}

export const ManagementUserOrganismAdminFormData: React.FC<IManagementUserOrganismAdminFormDataProps> = ({
    generalUserData,
    setIsValidTab,
    onSelectorChange,
    onInputChange, 
    editMode
}) => {

    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER" });
    const dispatch = useDispatch();
    const theme = useTheme();
    const style = ManagementUserDefaultFormStyle({ theme });
    const organismTypes = useAppSelector((store) => store.catalog.organisms.data);
    const dischargedDate = generalUserData.unregistered ? convertToLocal(generalUserData.unregisteredOn, FormatDate.DATE) : "";

    const {
        register,
        formState: { errors: error, isValid },
    } = useForm<IManagementUserForm>({ mode: "onChange", resolver: myProfileResolver });

    useEffect(() => {
        !organismTypes.length && dispatch(fetchOrganisms());
    }, [organismTypes]);

    useEffect(() => {
        setIsValidTab(generalUserData.organismId !== undefined && isValid);
    }, [generalUserData.organismId, isValid]);

    return (
        <div className={`  ${style.containerForm}`}>
            <div
                className={`ms-Grid ${style.gridContainer}`}
                dir="ltr"
            >
                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <Selector
                            key={String(generalUserData.organismId) + t("TYPE")}
                            id={String(generalUserData.organismId)}
                            title={t("TYPE")}
                            options={mapIDescriptionToDropDownOptions(organismTypes)}
                            selectedKey={generalUserData.organismId}
                            name={compileNameOfProperty<IManagementUserForm>("organismId")}
                            onChange={onSelectorChange}
                            disabled={generalUserData.id ? true : false}
                            required={true}
                        />
                    </div>
                    <div className={GridClass.LONG_DOBLE_CELL}></div>
                </div>
                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.SIMPLE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData.name ?? ""}
                            label={t("NAME")}
                            error={error.name}
                            {...register(compileNameOfProperty<IManagementUserForm>("name"), {
                                onChange: onInputChange,
                                value: generalUserData.name,
                                required: true,
                            })}
                            isRequired={true}
                        />
                    </div>
                </div>
                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData.email ?? ""}
                            maxLength={255}
                            label={t("EMAIL")}
                            {...register(compileNameOfProperty<IManagementUserForm>("email"), {
                                onChange: onInputChange,
                                value: generalUserData.email,
                                required: true,
                            })}
                            error={error.email}
                            disabled={editMode}
                            isRequired={true}
                        />
                    </div>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData.phoneNumber ?? ""}
                            maxLength={255}
                            label={t("PHONE_NUMBER")}
                            error={error.phoneNumber}
                            {...register(compileNameOfProperty<IManagementUserForm>("phoneNumber"), {
                                onChange: onInputChange,
                                value: generalUserData.phoneNumber,
                                required: false,
                            })}
                        />
                    </div>
                </div>
                {generalUserData.unregistered && (
                    <div className={GridClass.ROW_GRID}>
                        <div className={GridClass.SIMPLE_CELL}>
                            <LabelText
                                key={t("STATE") + generalUserData.unregisteredOn}
                                name={t("STATE")}
                                value={`${t("DISCHARGED")} - ${dischargedDate}`}
                                className={style.discharged}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
