import { IButtonStyles, IIconProps, IconButton, TooltipHost } from "@fluentui/react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { toast } from "react-toastify";
import { NameIcons } from "../../../../../assets/icons";
import { PopUpConfirmationType } from "../../../../../common/enum/PopUpConfirmationType";
import { IGenericPayload } from "../../../../../models";
import { postInspectionMultipleActuations } from "../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
    IAddInspectionData,
    resetAddInspectionFormDataState,
    updateAddInspectionFormProperty,
} from "../../../../../redux/reducers/request/list/form/addInspection";
import { getGenericPayloadFromEvent } from "../../../../../utils";
import { PopUpConfirmation } from "../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../../common/popup/popUpForm/popUpForm";
import { AddActuationForm } from "./controller/controller";
import { RequestListAddActuationsStyles } from "./requestListAddActuationsStyles.jss";
import { RequiredFieldsMessage } from "../../../../common/requiredMessage/requiredMessage";

export const RequestListAddActuations = () => {
    const theme = useTheme();
    const styles = RequestListAddActuationsStyles({ theme });
    const { t } = useTranslation("common", { keyPrefix: "LIST.ACTUATION.MULTIPLE" });
    const dispatch = useAppDispatch();
    const {
        requestlist: { list },
        addInspectionForm: { form },
    } = useAppSelector((state) => state);
    const icon: IIconProps = { iconName: NameIcons.ADDACTUATIONMULTIPLE };
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { control, register, reset } = useForm<IAddInspectionData>({ mode: "onChange" });

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: styles.iconBtn,
        icon: styles.iconBtn,
    };

    const resetStates = () => {
        setIsLoading(false);
        setShowConfirmDialog(false);
        setShowModal(false);
    };

    const onSave = useCallback(() => {
        const requestToChange = list.filter((i) => i.changeDate);
        if (requestToChange.length === 0) {
            toast.warning(t("SELECT_ANY_REQUEST"));
            return;
        }
        setShowConfirmDialog(true);
    }, [list]);

    const openModal = useCallback(() => {
        const requestToChange = list.filter((i) => i.changeDate);
        if (requestToChange.length === 0) {
            toast.warning(t("SELECT_ANY_REQUEST"));
            return;
        }
        setShowModal(true);
    }, [list]);

    const onAccept = useCallback(() => {
        setShowConfirmDialog(false);
        setIsLoading(true);
        const requestToChange = list.filter((i) => i.changeDate);
        if (requestToChange.length === 0) {
            setIsLoading(false);
            toast.warning(t("SELECT_ANY_REQUEST"));
            return;
        }
        const requestIds = requestToChange.map(({ id }) => +id!);

        dispatch(postInspectionMultipleActuations(requestIds, form)).then((response: boolean) => {
            response && onClose();
        });
    }, [dispatch, list, form]);

    const onClose = useCallback(() => {
        resetStates();
        reset();
        dispatch(resetAddInspectionFormDataState());
    }, [dispatch]);

    const onCheckBoxChange = useCallback(
        (event: any) => {
            const payload: IGenericPayload = {
                name: event.target.name,
                value: event.target.checked,
            };
            dispatch(updateAddInspectionFormProperty(payload));
        },
        [dispatch],
    );

    const onInputChange = useCallback(
        (event: any, _?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateAddInspectionFormProperty(payload));
        },
        [dispatch],
    );

    const onSelectorChange = useCallback(
        (option: any, name: string) => {
            const payload: IGenericPayload = {
                name,
                value: option.key,
            };
            dispatch(updateAddInspectionFormProperty(payload));
        },
        [dispatch],
    );

    const isValid = useMemo(() => {
        return form.organismId !== 0 && form.typeId !== 0;
    }, [form]);

    return (
        <>
            <TooltipHost
                content={t("TOOLTIP")}
                styles={{ root: { width: 30, display: "block" } }}
            >
                <IconButton
                    onClick={openModal}
                    iconProps={icon}
                    styles={iconButtonStyles}
                />
            </TooltipHost>

            <PopUpForm
                content={
                    <AddActuationForm
                        form={form}
                        onCheckBoxChange={onCheckBoxChange}
                        onInputChange={onInputChange}
                        onSelectorChange={onSelectorChange}
                        control={control}
                        register={register}
                    />
                }
                leftButtonContent={<RequiredFieldsMessage />}
                title={t("TITLE")}
                isVisible={showModal}
                onCancel={onClose}
                onSave={onSave}
                width={"25vw"}
                height={"calc(60vh - 10rem)"}
                isLoading={isLoading}
                isDisabledButton={!isValid}
            />
            <PopUpConfirmation
                width={"40vw"}
                height={150}
                type={PopUpConfirmationType.WARNING}
                showPopUp={showConfirmDialog}
                message={t("WARNING")}
                onCancel={() => setShowConfirmDialog(false)}
                onAccept={onAccept}
            />
        </>
    );
};
