import { useEffect, useState } from "react";
import { unstable_useBlocker as useBlocker, useNavigate } from "react-router-dom";

export const useBlockerHook = (isDirtyForm: boolean, exit: boolean) => {
    const blocker = useBlocker(isDirtyForm);
    const navigate = useNavigate();

    const [showWarningBack, setShowWarningBack] = useState(false);

    useEffect(() => {
        if (blocker.state === "blocked" && !isDirtyForm) {
            blocker.reset();
            exit && navigate(blocker.location.pathname);
        }

        if (blocker.state === "blocked" && isDirtyForm) {
            setShowWarningBack(true);
        }
    }, [blocker, isDirtyForm, exit]);

    return {
        showWarningBack,
        setShowWarningBack,
    };
};
