import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { MasterDisplayDocksForm } from "./form/form";
import { validateDefaultForm } from "../../../utils/utils";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { postToMasterDocksForm } from "../../../../../../redux/actions/resources/master/docks/docks";
import { resetMasterDocksForm } from "../../../../../../redux/reducers/resources/master/docks/form";
import { clearMasterPopUp } from "../../../../../../redux/reducers/resources/master/master";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";

interface IMasterDisplayDocksFormController {
    widthPopUp: any;
    heigthPopUp: any;
}
export const MasterDisplayDocksFormController: React.FC<IMasterDisplayDocksFormController> = ({ heigthPopUp, widthPopUp }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { form, loading } = useAppSelector((state) => state.masterDocksForm);
    const onDimissed = () => {
        dispatch(resetMasterDocksForm());
    };
    const onCancelPopUp = () => {
        dispatch(clearMasterPopUp());
    };

    const onSavePopUp = () => {
        dispatch(postToMasterDocksForm()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };
    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE") + " " + t("BUTTON.DOCKS");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE") + " " + t("BUTTON.DOCKS");
        }

        return "";
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, false);
    }, [form]);

    return (
        <PopUpForm
            content={<MasterDisplayDocksForm />}
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
            onCancel={onCancelPopUp}
            onSave={onSavePopUp}
            onDimissed={onDimissed}
            height={heigthPopUp}
            width={widthPopUp}
            isLoading={loading}
            isDisabledButton={isDisabledButton}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
