import { useTheme } from "react-jss";
import { generatePath, useNavigate, useParams } from "react-router";
import { routeUrls } from "../../../../common/constants";
import { IRequestDetail } from "../../../../models/requests/IRequestDetail";
import { ButtonPrimary } from "../../../common/buttons/buttons";
import { RequestListCardStyle } from "./requestCard.jss";
import { CustomIcons } from "../../../common/customIcons/customIcons";
import { useEffect, useState } from "react";
import { DefaultValues } from "../../../../common/enum/DefaultValues";
import { useTranslation } from "react-i18next";
import { RequestCardColumns } from "./columns/columns";

interface IProps {
    data: IRequestDetail;
    forceShow?: boolean;
    showEdit: boolean;
}
export const RequestCard: React.FC<IProps> = ({ data, forceShow, showEdit }) => {
    const { t } = useTranslation("common");
    const [showCardData, setShowCard] = useState<boolean>(false);
    const theme = useTheme();
    const styles = RequestListCardStyle({ theme, showCardData });
    const columns = RequestCardColumns();
    const navigate = useNavigate();
    const { impersonatedOrganismId } = useParams();

    const iconDrop = showCardData ? "ChevronUpSmall" : "ChevronDownSmall";

    const onRequestClick = (id: string | undefined) => {
        let path = "";
        if (impersonatedOrganismId) {
            path = generatePath(routeUrls.ORGANISM_EDIT_REQUEST, { impersonatedOrganismId: impersonatedOrganismId, id: id });
        } else {
            path = generatePath(routeUrls.EDIT_REQUEST, { id });
        }

        navigate(path);
    };

    useEffect(() => {
        if (forceShow && !showCardData) {
            setShowCard(true);
        } else if (!forceShow) {
            setShowCard(false);
        }
    }, [forceShow]);

    return (
        <div className={styles.card}>
            <div className={styles.cardContent}>
                <div className={styles.containerHeader}>
                    <div className={styles.iconColumn}>
                        <CustomIcons
                            size={25}
                            iconName={iconDrop}
                            className={` ${styles.iconDropDownActivated}`}
                            onClick={() => {
                                setShowCard(!showCardData);
                            }}
                            margin={"0px"}
                        />
                    </div>
                    {columns
                        .filter((column) => column.responsive?.header === true)
                        .map((item) => {
                            return (
                                <div className={styles.container}>
                                    <div className={styles.title}>{item.name}</div>
                                    <div className={styles.description}>{item.onRender(data) || DefaultValues.EMPTY_LIST}</div>
                                </div>
                            );
                        })}
                </div>

                {showCardData && (
                    <div
                        className={styles.containerHidden}
                        style={{ height: "auto" }}
                    >
                        <div className={styles.content}>
                            {columns
                                .filter((column) => column.responsive?.detail === true)
                                .map((item) => {
                                    return (
                                        <div className={`${styles.container} ${item.responsive?.totalWidth ? styles.containerFullWidth : null}`}>
                                            <div className={styles.title}>{item.name}</div>
                                            <div className={styles.description}>{item.onRender(data) || DefaultValues.EMPTY_LIST}</div>
                                        </div>
                                    );
                                })}
                        </div>
                        {showEdit && (
                            <div className={styles.actionRow}>
                                <ButtonPrimary
                                    icon={"EditIcon"}
                                    className={styles.iconEditIcon}
                                    handleClick={() => onRequestClick(data.id)}
                                    title={t("COMMON.UPDATE")}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
