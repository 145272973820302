import { useTheme } from "react-jss";
import { RequestListAddActuations } from "../../component/requestListAddActuations/requestListAddActuations";
import { RequestListChangeDatePicker } from "../../component/requestListChangeDatePicker/requestListChangeDatePicker";
import { RequestListDatePicker } from "../../component/requestListDatePicker/requestListDatePicker";
import { RequestFilterPivot } from "./filter/filter";
import { RequestListFilterStyle } from "./pivotStyle.jss";

interface IRequestListFilterPivotContainerProps {
    extendedHeight: boolean;
    showChangeDate: boolean;
    addMultipleActuation: boolean;
}

export const RequestListFilterPivotContainer: React.FC<IRequestListFilterPivotContainerProps> = ({
    extendedHeight,
    showChangeDate,
    addMultipleActuation,
}) => {
    const theme = useTheme();
    const styles = RequestListFilterStyle({ theme, extendedHeight });

    return (
        <div className={styles.container}>
            <div className={styles.dateContainer}>
                <div className="datePicker">
                    <RequestListDatePicker />
                </div>
                {(showChangeDate || addMultipleActuation) && (
                    <>
                        <div className={styles.horizontalWhiteSeparator}></div>
                        <div className={styles.actionsContainer}>
                            {showChangeDate && (
                                <div className="dateChangePicker">
                                    <RequestListChangeDatePicker />
                                </div>
                            )}
                            {addMultipleActuation && (
                                <>
                                    <div className={styles.addActuations}>
                                        <RequestListAddActuations />
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className={styles.verticalWhiteSeparator}></div>

            <RequestFilterPivot  />
        </div>
    );
};
