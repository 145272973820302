import { IDropdownOption } from "@fluentui/react";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { componentsMediaQueries } from "../../../../../common/constants";
import { IGenericPayload } from "../../../../../models";
import { IManagementRequestDatePermissionForm } from "../../../../../models/management/requestdatepermission/IRequestdatepermission";
import { useAppSelector } from "../../../../../redux/hooks";
import { updateRequestDatePermissionHeaderPropertyForm } from "../../../../../redux/reducers/management/requestdatepermission/form";
import { compileNameOfProperty } from "../../../../../utils";
import { Checkboxs } from "../../../../common/checkbox/checkbox";
import { TimePickerComponent } from "../../../../common/timePicker/timePicker";
import { ContainerSizeSelector, OrganismSelector, TransportUnitSelector } from "../../../../request/common/selectors";
import { RequestDayPermissionSelector } from "../../../../request/common/selectors/requestDayPermission/requestDayPermission";

import { ManagementRequestDatePermisionDateFormStyle } from "./formStyle.jss";
import { MultiWeekDaySelector } from "../../../../common/selectors/controllerSelectors/weekDaySelector/multiWeekDaySelector";

interface IManagementRequestDatePermisionFormProps {
    isContainer: boolean;
}

export const ManagementRequestDatePermisionForm: React.FC<IManagementRequestDatePermisionFormProps> = ({ isContainer }) => {
    const isMobile = useMediaQuery({ query: componentsMediaQueries.MOBILE });
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.PERMISSIONS.FORM" });
    const { form } = useAppSelector((state) => state.managementRequestDatePermissionForm);
    const dispatch = useDispatch();
    const theme = useTheme();
    const style = ManagementRequestDatePermisionDateFormStyle({ theme });

    const { control } = useForm<IManagementRequestDatePermissionForm>({ mode: "onChange" });

    const onUpdateHeaderPopperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name: name,
            value: value,
        };
        dispatch(updateRequestDatePermissionHeaderPropertyForm(payload));
    };

    const onSelectorChange = (option: IDropdownOption, propertyName: string) => {
        type ObjectKey = keyof typeof form;
        const property = propertyName as ObjectKey;

        if (form[property] !== option.key) {
            onUpdateHeaderPopperty(propertyName, option.key);
        }
    };

    const onTimeChange = useCallback(
        (date: MaterialUiPickersDate) => {
            date &&
                onUpdateHeaderPopperty(compileNameOfProperty<IManagementRequestDatePermissionForm>("time"), new Date(date.toISOString()).toString());
        },
        [dispatch],
    );

    const onCheckboxChange = useCallback(
        (event?: any, checked = false) => {
            onUpdateHeaderPopperty(event.target.name, checked);
        },
        [dispatch],
    );

    const onMultipleNumberKeysChange = useCallback(
        (name: string, numberOptions: number[]) => {
            onUpdateHeaderPopperty(name, numberOptions);
        },
        [dispatch],
    );

    return (
        <div className={style.form}>
            <div className={style.rowForm}>
                <TransportUnitSelector
                    onChange={onSelectorChange}
                    selectedKey={form.transportUnitId}
                    propertyName={compileNameOfProperty<IManagementRequestDatePermissionForm>("transportUnitId")}
                    title={t("UTI")}
                    control={control}
                    isRequired={true}
                    rules={{ required: true }}
                />
                <ContainerSizeSelector
                    onChange={onSelectorChange}
                    selectedKey={form.transportUnitSizeId}
                    propertyName={compileNameOfProperty<IManagementRequestDatePermissionForm>("transportUnitSizeId")}
                    disabled={form.transportUnitId ? !isContainer : false}
                    title={t("SIZE")}
                    isRequired={isContainer}
                    control={control}
                    rules={{ required: isContainer }}
                />
            </div>
            <div className={style.rowForm}>
                <OrganismSelector
                    onChange={onSelectorChange}
                    selectedKey={form.organismId}
                    propertyName={compileNameOfProperty<IManagementRequestDatePermissionForm>("organismId")}
                    title={t("ORGANISM")}
                    isRequired={true}
                    control={control}
                    rules={{ required: true }}
                />
            </div>
            <div className={style.rowForm}>
                <RequestDayPermissionSelector
                    onChange={onSelectorChange}
                    selectedKey={form.referenceDayId}
                    propertyName={compileNameOfProperty<IManagementRequestDatePermissionForm>("referenceDayId")}
                    title={t("DAY")}
                    isRequired={true}
                    control={control}
                    rules={{ required: true }}
                />

                {!isMobile && (
                    <TimePickerComponent
                        onChange={onTimeChange}
                        value={form.time ? new Date(form.time) : null}
                        label={t("HOUR")}
                        required={true}
                    />
                )}
            </div>
            {isMobile && (
                <div className={style.rowFormMobile}>
                    <TimePickerComponent
                        onChange={onTimeChange}
                        value={form.time ? new Date(form.time) : null}
                        label={t("HOUR")}
                        required={true}
                    />
                    <span className={style.checkWorkDay}>
                        <Checkboxs
                            label={t("WORK_DAY")}
                            name={compileNameOfProperty<IManagementRequestDatePermissionForm>("workDays")}
                            isChecked={form.workDays}
                            handleChange={onCheckboxChange}
                        />
                    </span>
                </div>
            )}

            <div className={style.rowForm}>
                {!isMobile && (
                    <span className={style.checkWorkDay}>
                        <Checkboxs
                            label={t("WORK_DAY")}
                            name={compileNameOfProperty<IManagementRequestDatePermissionForm>("workDays")}
                            isChecked={form.workDays}
                            handleChange={onCheckboxChange}
                        />
                    </span>
                )}
                <MultiWeekDaySelector
                    onChange={onMultipleNumberKeysChange}
                    selectedKeys={form.weekDays}
                    propertyName={compileNameOfProperty<IManagementRequestDatePermissionForm>("weekDays")}
                    title={t("APLICATION_DAY")}
                />
            </div>
        </div>
    );
};
