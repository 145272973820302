import React, { useEffect } from "react";
import { fetchAllInspectorOptions } from "../../../../../redux/actions/user/users/users";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { StringMultiSelector } from "../../../../common/selectors";
import { IBaseMultipleStringSelectorProps } from "../IBaseMultipleSelectorProps";
import { MultipleStringSelectorFilter } from "../../../common/selectors/multipleSelectorFilter/MultipleStringSelectorFilter";

interface IMultipleInspectorRoleSelectorProps extends IBaseMultipleStringSelectorProps {
    organismId?: number;
}

export const MultipleInspectorRoleSelector: React.FC<IMultipleInspectorRoleSelectorProps> = ({
    autocomplete,
    onChange,
    propertyName,
    title,
    selectedKeys,
    organismId: selectorOrganismId,
}) => {
    const { options, loading } = useAppSelector((store) => store.usersByRole.inspector);
    const { organismId } = useAppSelector((state) => state.userData.profile);
    const dispatch = useAppDispatch();

    useEffect(() => {
        organismId !== undefined && dispatch(fetchAllInspectorOptions(selectorOrganismId ?? organismId));
    }, [dispatch, organismId, selectorOrganismId]);

    return autocomplete ? (
        <MultipleStringSelectorFilter
            selectedKeys={selectedKeys}
            onChange={onChange}
            options={options}
            label={title}
            propertyName={propertyName}
        />
    ) : (
        <StringMultiSelector
            onChange={onChange}
            selectedKeys={selectedKeys}
            options={options}
            disabled={loading}
            title={title}
            name={propertyName}
        />
    );
};
