import { createUseStyles } from "react-jss";

export const TextareaStyle = createUseStyles((theme: any) => ({
    input: {
        backgroundColor: "transparent",
        border: `1.3px solid ${theme.colors.form.input.default}`,
        color: theme.colors.form.input.filled,
        fontFamily: theme.fonts.families.site,
        fontSize: theme.fonts.sizes.base,
        padding: `5px 0`,
        minHeight: "20px",
        maxHeight: "95%",
        resize: "none",
        display: "block",
        width: "99%",
        height:"100%",
        "&:focus": {
            outline: "none",
            borderColor: theme.colors.form.input.focus,
        },
        "&:valid, &:focus-visible": {
            outline: "none",
            backgroundColor: "transparent",
        },
        "&:disabled": {
            borderBottom: "none",
            fontWeight: "200",
        },
    },
    label:{
        fontFamily: theme.fonts.families.site,
        color: theme.colors.form.input.default,
    }, 
    error: {
        fontFamily: theme.fonts.families.site,

        fontSize: theme.fonts.sizes.xs,
        color: theme.colors.form.input.invalid,
        padding: theme.paddings.forms.errorsMessage.defaultPadding,
        marginTop:3
    },
}));
