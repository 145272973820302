import { RequestHistoryTimeLineActions } from "../../../../../../../common/enum/requestHistoryTimeLine";

enum HistoryActionsIcons {
    ADD = "Add",
    UPDATE = "Edit",
    DELETE = "Delete",
    CALENDAR = "Calendar",
    MESSAGE = "Message",
    MAIL = "Mail",
    SYNC = "Sync",
    CANCEL = "Cancel",
    ARROWTALLDOWNRIGHT = "ArrowTallDownRight",
    CHECKMARK = "CheckMark",
    PAGE = "Page",
    PHOTO = "Photo",
    ADDFRIEND = "AddFriend",
    ARROWTALLUPLEFT = "ArrowTallUpLeft",
    MAILUNDELIVERED = "MailUndelivered",
    DATETIME2 = "DateTime2",
    DATETIMEMIRRORED = "DateTimeMirrored",
    EDITCONTACT = "EditContact",
    MAILCHECK = "MailCheck",
    BLOCKCONTACT = "BlockContact",
    EVENTDATEMISSED = "EventDateMissed12",
}

const actionToIconMap: { [key: string]: HistoryActionsIcons } = {
    [RequestHistoryTimeLineActions.CREATE_REQUEST]: HistoryActionsIcons.ADD,
    [RequestHistoryTimeLineActions.UPDATE_REQUEST]: HistoryActionsIcons.UPDATE,
    [RequestHistoryTimeLineActions.UPDATE_REQUEST_STATE_HISTORY]: HistoryActionsIcons.UPDATE,
    [RequestHistoryTimeLineActions.UPDATE_REQUEST_DATE]: HistoryActionsIcons.CALENDAR,
    [RequestHistoryTimeLineActions.UPDATE_STATE]: HistoryActionsIcons.UPDATE,
    [RequestHistoryTimeLineActions.UPDATE_DOCK]: HistoryActionsIcons.UPDATE,
    [RequestHistoryTimeLineActions.UPDATE_REQUEST_CUSTOMER_NOTIFIED_ON]: HistoryActionsIcons.MESSAGE,
    [RequestHistoryTimeLineActions.UPDATE_REQUEST_CUSTOMER]: HistoryActionsIcons.EDITCONTACT,
    [RequestHistoryTimeLineActions.CREATE_INSPECTION]: HistoryActionsIcons.ADD,
    [RequestHistoryTimeLineActions.UPDATE_INSPECTION]: HistoryActionsIcons.UPDATE,
    [RequestHistoryTimeLineActions.DELETE_INSPECTION]: HistoryActionsIcons.DELETE,
    [RequestHistoryTimeLineActions.CREATE_ACTUATION]: HistoryActionsIcons.ADD,
    [RequestHistoryTimeLineActions.UPDATE_ACTUATION]: HistoryActionsIcons.UPDATE,
    [RequestHistoryTimeLineActions.DELETE_ACTUATION]: HistoryActionsIcons.DELETE,
    [RequestHistoryTimeLineActions.CREATE_MULTIPLE_ACTUATION]: HistoryActionsIcons.ADD,
    [RequestHistoryTimeLineActions.ASSIGN_INSPECTOR]: HistoryActionsIcons.ADDFRIEND,
    [RequestHistoryTimeLineActions.UNASSIGN_INSPECTOR]: HistoryActionsIcons.BLOCKCONTACT,
    [RequestHistoryTimeLineActions.CREATE_DOCUMENT]: HistoryActionsIcons.PAGE,
    [RequestHistoryTimeLineActions.DELETE_DOCUMENT]: HistoryActionsIcons.DELETE,
    [RequestHistoryTimeLineActions.CREATE_PHOTO]: HistoryActionsIcons.PHOTO,
    [RequestHistoryTimeLineActions.DELETE_PHOTO]: HistoryActionsIcons.DELETE,
    [RequestHistoryTimeLineActions.CREATE_ORGANISM_INCIDENCE]: HistoryActionsIcons.ADD,
    [RequestHistoryTimeLineActions.UPDATE_ORGANISM_INCIDENCE]: HistoryActionsIcons.UPDATE,
    [RequestHistoryTimeLineActions.DELETE_ORGANISM_INCIDENCE]: HistoryActionsIcons.DELETE,
    [RequestHistoryTimeLineActions.CREATE_OPERATIONAL_INCIDENCE]: HistoryActionsIcons.ADD,
    [RequestHistoryTimeLineActions.UPDATE_OPERATIONAL_INCIDENCE]: HistoryActionsIcons.UPDATE,
    [RequestHistoryTimeLineActions.DELETE_OPERATIONAL_INCIDENCE]: HistoryActionsIcons.DELETE,
    [RequestHistoryTimeLineActions.UPDATE_INVOICE_CONCEPT]: HistoryActionsIcons.UPDATE,
    [RequestHistoryTimeLineActions.DELETE_INVOICE_CONCEPT]: HistoryActionsIcons.DELETE,
    [RequestHistoryTimeLineActions.CREATE_INSPECTION_CARD]: HistoryActionsIcons.ADD,
    [RequestHistoryTimeLineActions.UPDATE_INSPECTION_CARD]: HistoryActionsIcons.UPDATE,
    [RequestHistoryTimeLineActions.VALIDATE_INSPECTION_CARD]: HistoryActionsIcons.CHECKMARK,
    [RequestHistoryTimeLineActions.UNVALIDATE_INSPECTION_CARD]: HistoryActionsIcons.CANCEL,
    [RequestHistoryTimeLineActions.CREATE_INVOICE_CONCEPT]: HistoryActionsIcons.ADD,
    [RequestHistoryTimeLineActions.NEW_REQUEST_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.NEW_REQUEST_WITH_PARKING_RESTRICTIONS_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.NEW_REQUEST_VALENCIAPORT_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.NEW_REQUEST_VUA_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.NEW_REQUEST_FUMIGATED_CONTAINER_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.ENTRY_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.ENTRY_WITH_SAMPLING_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.VALENCIAPORT_ACK_FROM_INSPECTION_APPOINTMENT_INTEGRATION]: HistoryActionsIcons.ARROWTALLDOWNRIGHT,
    [RequestHistoryTimeLineActions.VALENCIAPORT_ACK_FROM_INSPECTION_RESULT_INTEGRATION]: HistoryActionsIcons.ARROWTALLDOWNRIGHT,
    [RequestHistoryTimeLineActions.VALENCIAPORT_INSPECTION_RESULT_INTEGRATION]: HistoryActionsIcons.ARROWTALLUPLEFT,
    [RequestHistoryTimeLineActions.VALENCIAPORT_INSPECTION_APPOINTMENT_INTEGRATION]: HistoryActionsIcons.ARROWTALLUPLEFT,
    [RequestHistoryTimeLineActions.PREPARED_CONFIRMED_REQUEST]: HistoryActionsIcons.MAILCHECK,
    [RequestHistoryTimeLineActions.PREPARED_CONFIRMED_REQUEST_ERROR]: HistoryActionsIcons.MAILUNDELIVERED,
    [RequestHistoryTimeLineActions.DELETE_PREPARED_CONFIRMED_REQUEST]: HistoryActionsIcons.MAILUNDELIVERED,
    [RequestHistoryTimeLineActions.DELETE_PREPARED_CONFIRMED_REQUEST_ERROR]: HistoryActionsIcons.MAILUNDELIVERED,
    [RequestHistoryTimeLineActions.DOCK_ASSIGNMENT_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.DOCK_START_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.DOCK_END_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.NEW_INCIDENCE_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.NEW_ACTUATION_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.NEW_ACTUATION_WITH_INSTRUCTIONS_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.NEW_DOCUMENT_FUMIGATED_CONTAINER_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.NEW_DOCUMENT_ORGANISM_ACTUATION_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.APPOINTMENT_CONFIRMATION_EMAIL]: HistoryActionsIcons.MAIL,
    [RequestHistoryTimeLineActions.APPOINTMENT_CONFIRMATION_SORT_EMAIL]: HistoryActionsIcons.SYNC,
    [RequestHistoryTimeLineActions.APPOINTMENT_INSPECTION_RECEIVED]: HistoryActionsIcons.DATETIME2,
    [RequestHistoryTimeLineActions.INVOICE_CONCEPT_CREATED_SYNCHRONIZED]: HistoryActionsIcons.SYNC,
    [RequestHistoryTimeLineActions.INVOICE_CONCEPT_UPDATED_SYNCHRONIZED]: HistoryActionsIcons.SYNC,
    [RequestHistoryTimeLineActions.INVOICE_CONCEPT_DELETED_SYNCHRONIZED]: HistoryActionsIcons.SYNC,
    [RequestHistoryTimeLineActions.INVOICE_CONCEPT_INVOICED_SYNCHRONIZED]: HistoryActionsIcons.SYNC,
    [RequestHistoryTimeLineActions.CREATE_APPOINTMENT]: HistoryActionsIcons.CALENDAR,
    [RequestHistoryTimeLineActions.UPDATE_APPOINTMENT]: HistoryActionsIcons.UPDATE,
    [RequestHistoryTimeLineActions.DELETE_APPOINTMENT]: HistoryActionsIcons.EVENTDATEMISSED,
    [RequestHistoryTimeLineActions.VALIDATION_PARKING_ACCEPTED]: HistoryActionsIcons.CHECKMARK,
    [RequestHistoryTimeLineActions.VALIDATION_PARKING_REJECTED]: HistoryActionsIcons.CANCEL,
    [RequestHistoryTimeLineActions.PARKING_BARRIER_ALARM_EMAIL]: HistoryActionsIcons.MAILCHECK,
    [RequestHistoryTimeLineActions.APBA_INTEGRATION_KO]: HistoryActionsIcons.MAILUNDELIVERED,
    [RequestHistoryTimeLineActions.APBA_INTEGRATION_OK]: HistoryActionsIcons.MAILCHECK,
};

export const historyActions = Object.entries(RequestHistoryTimeLineActions).map(([key, value]) => ({
    action: value,
    label: key,
    icon: actionToIconMap[value],
}));
