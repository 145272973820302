import { IDropdownOption } from "@fluentui/react";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";

import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";

import { IGenericPayload, IOrganism } from "../../../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";

import { mapIDescriptionToDropDownOptions } from "../../../../../../../utils";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils/formUtils";
import { FormField } from "../../../../../../common/fields/fields";
import { Selector } from "../../../../../../common/selectors/selector";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { NotDataWarningMessage } from "../../../../notDataWarning/notDataWarning";
import { MasterOrganismFormStyle } from "./formStyle.jss";
import { IMasterOrganismItem } from "../../../../../../../models/resources/master/IOrganism";
import { setMasterOrganismForm, updateMasterOrganismProperty } from "../../../../../../../redux/reducers/resources/master/organism/form";
interface IMasterOrganismFormProps {
    showNotData: boolean;
    data: IOrganism[];
}

export const MasterOrganismForm: React.FC<IMasterOrganismFormProps> = ({ showNotData, data }) => {
    const theme = useTheme();
    const style = MasterOrganismFormStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { form } = useAppSelector((state) => state.masterOrganismForm);
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { register, control } = useForm<IMasterOrganismItem>({ mode: "onChange" });

    const onUpdateFormProperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };

        dispatch(updateMasterOrganismProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(name, value);
        },
        [dispatch],
    );
    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateFormProperty(propertyName, !!+option.key);
    };
    const onSelectorChange = useCallback(
        (event: any, option: any, name: string) => {
            onUpdateFormProperty(name, option.key);

            let organism = data.find((item) => {
                return item.id === option.key;
            });
            if (organism) {
                const form: IMasterOrganismItem = {
                    active: true,
                    viewAll: false,
                    referenceId: String(option.key),
                    code: organism.code,
                    description: organism.description,
                    internal: organism.internal,
                };

                dispatch(setMasterOrganismForm(form));
            }
        },
        [dispatch, data],
    );
    const disabledInput = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !form.referenceId;
    }, [showPopUp, form]);
    return showNotData && showPopUp === PopUpCodes.CREATE ? (
        <NotDataWarningMessage></NotDataWarningMessage>
    ) : (
        <div className={style.form}>
            {!form.id && (
                <div className={style.rowForm}>
                    <Selector
                        selectedKey={Number(form.referenceId)}
                        name={compileNameOfProperty<IMasterOrganismItem>("referenceId")}
                        title={t("FORM.ORGANIMS_SELECTOR")}
                        options={mapIDescriptionToDropDownOptions(data)}
                        onChange={onSelectorChange}
                        required={true}
                    />
                </div>
            )}

            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code ?? DefaultValues.NOT_TEXT}
                    disabled={true}
                    {...register(compileNameOfProperty<IMasterOrganismItem>("code"), {
                        onChange: onInputChange,
                        value: form.code ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                />

                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? DefaultValues.NOT_TEXT}
                    {...register(compileNameOfProperty<IMasterOrganismItem>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>
            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.viewAll}
                    propertyName={compileNameOfProperty<IMasterOrganismItem>("viewAll")}
                    title={t("VIEW_ALL")}
                    control={control}
                    disabled={disabledInput}
                    isRequired={true}
                />
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.internal}
                    propertyName={compileNameOfProperty<IMasterOrganismItem>("internal")}
                    title={t("INTERNAL")}
                    control={control}
                    disabled={disabledInput}
                    isRequired={true}
                />
            </div>
            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterOrganismItem>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    disabled={disabledInput}
                    isRequired={true}
                />
            </div>
        </div>
    );
};
