import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { postAndPutConfigDockMerchandise, removeConfigDockMerchandise } from "../../../../redux/actions/config/dockMerchandise";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetConfigDockMerchandiseForm, setConfigDockMerchandiseShowPopUp } from "../../../../redux/reducers/config/dockMerchandise/form";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { ConfigDockMerchandiseForm } from "./form/form";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";

export const ConfigDockMerchandiseFormController = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const { loading, form, showPopUp } = useAppSelector((state) => state.configDockMerchandiseForm);
    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE");
        }

        return "";
    }, [showPopUp]);

    const onCancelPopUp = () => {
        dispatch(setConfigDockMerchandiseShowPopUp(PopUpCodes.NONE));
    };

    const onAcceptPopUp = () => {
        if (showPopUp === PopUpCodes.UPDATE || showPopUp === PopUpCodes.CREATE) {
            dispatch(postAndPutConfigDockMerchandise()).then((response: any) => {
                response && onCancelPopUp();
            });
        } else if (showPopUp === PopUpCodes.DELETE) {
            dispatch(removeConfigDockMerchandise()).then((response: any) => {
                if (response) {
                    onCancelPopUp();
                    onDimissed();
                }
            });
        }
    };
    const onDimissed = () => {
        dispatch(resetConfigDockMerchandiseForm());
    };

    const isDisabledButton = useMemo(() => {
        const keys = ["dockId", "merchandiseOriginId", "merchandiseTypeId"];
        let item: any = form;
        for (let key of keys) {
            if (!item[key]) {
                return true;
            }
        }
        return false;
    }, [form]);

    return (
        <>
            <PopUpForm
                content={<ConfigDockMerchandiseForm />}
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={onCancelPopUp}
                onSave={onAcceptPopUp}
                isLoading={loading}
                height={"40vh"}
                isDisabledButton={isDisabledButton}
                leftButtonContent={<RequiredFieldsMessage />}
            />
            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCancelPopUp}
                onAccept={onAcceptPopUp}
                type={PopUpConfirmationType.DELETE}
                height={"auto"}
                width={"auto"}
                loading={loading}
                disabledActions={loading}
            />
        </>
    );
};
