import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { PopUpCodes } from "../../../../../../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../../../../../../common/enum/PopUpConfirmationType";
import { FormatDate } from "../../../../../../../../../common/enum/dateTime/FormatDate";
import { IConfigEditRequest } from "../../../../../../../../../models/requests/IConfigEditRequest";
import { IInspection } from "../../../../../../../../../models/requests/IEditRequest";
import { fetchOrganisms } from "../../../../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../redux/hooks";
import { setCertificatesByOrganism } from "../../../../../../../../../redux/reducers/catalog/catalog";
import { updateEditRequestInspectionFormProperty } from "../../../../../../../../../redux/reducers/request/edit/form/inspection";
import {
    compileNameOfProperty,
    convertToLocal,
    getGenericPayloadFromEvent,
    mapIDescriptionToDropDownOptions,
} from "../../../../../../../../../utils";
import { DateTimePickerComponent } from "../../../../../../../../common/dateTimePicker/dateTimePicker";
import { FormField } from "../../../../../../../../common/fields/fields";
import { PopUpConfirmation } from "../../../../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { ControlledRadioButtonGroup } from "../../../../../../../../common/radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { Selector } from "../../../../../../../../common/selectors/selector";
import { EditRequestInspectionFormStyle } from "./formStyle.jss";

interface IEditRequestInspectionFormProps {
    schema: IConfigEditRequest;
}

export const EditRequestInspectionForm: React.FC<IEditRequestInspectionFormProps> = ({ schema }) => {
    const theme = useTheme();
    const styles = EditRequestInspectionFormStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const dispatch = useAppDispatch();

    const [warningValidateInspectorArrive, setWarningValidateInspectorArrive] = useState<string>("");
    const [widthPopUp, setWidthPopUp] = useState<string>("auto");
    const [hasVerticalScroll, setHasVerticalScroll] = useState(false);
    const [showCancelButton, setShowCancelButton] = useState<boolean>(false);

    const {
        editRequestInspectionForm: { form, showPopUp },
        catalog: {
            organisms: { data: organismTypes },
            certificatesByOrganism: { data: certificateTypes },
            organisms: { data: allOrganisms },
        },
        editRequest: {
            headerRequest: { dockStartOn: requestDockStartOn, dockEndOn: requestDockEndon },
        },
    } = useAppSelector((store) => store);

    const enabledDocumentNumber = useMemo(() => showPopUp === PopUpCodes.UPDATE && !form.certificateTypeId && !form.certificateNumber, [showPopUp]);

    const disabledDocumentNumber = useMemo(() => {
        if (schema.inspection_certificateNumber.readonly) {
            return true;
        }

        if (enabledDocumentNumber) {
            return false;
        }

        return form.certificateNumberDisabled;
    }, [form, showPopUp, certificateTypes, enabledDocumentNumber]);

    useEffect(() => {
        const container = document.querySelector(`.${styles.generalContainer}`);
        if (container) {
            setHasVerticalScroll(container.scrollHeight > container.clientHeight);
        }
    }, [styles.generalContainer]);

    const requiredDocumentNumber = useMemo(() => {
        let automaticCertificateNumber = certificateTypes.find((item) => item.id === form.certificateTypeId)?.automaticCertificateNumber;
        return automaticCertificateNumber ? false : true;
    }, [form.certificateTypeId]);

    const {
        register,
        control,
        formState: { errors },
    } = useForm<IInspection>({ mode: "onChange" });

    const onUpdateFormProperty = (value: any, name: string) => {
        dispatch(updateEditRequestInspectionFormProperty({ name, value }));
    };
    const onSelectorChange = (_event: React.FormEvent<HTMLDivElement>, option: any, name: string) => {
        if (name === compileNameOfProperty<IInspection>("organismId")) {
            const organism = allOrganisms.find((item) => item.id === option.key);
            dispatch(setCertificatesByOrganism(organism ? organism.certificates : []));
            form.certificateTypeId && onUpdateFormProperty(0, compileNameOfProperty<IInspection>("certificateTypeId"));
        }

        onUpdateFormProperty(option.key, name);
    };

    const onRadioButtonChange = useCallback(
        (optionKey: string, name?: any) => {
            onUpdateFormProperty(!!+optionKey, name);
        },
        [dispatch],
    );

    const onDateChange = (date: Date | undefined | null, propertyName: string) => {
        if (!date) {
            return;
        }

        const dockEndOn = new Date(requestDockEndon);

        if (!requestDockStartOn) {
            setWarningValidateInspectorArrive(t("INFO.NOT_DOCK_ENTRY_ON"));
            setWidthPopUp("auto");
        } else if (date >= dockEndOn && requestDockEndon) {
            setWarningValidateInspectorArrive(
                t("INFO.MIN_DOCK_EXIT_ON", {
                    dockEndOn: convertToLocal(dockEndOn, FormatDate.DATE_HOUR_MINUTE),
                    interpolation: { escapeValue: false },
                }),
            );
            onUpdateFormProperty(requestDockEndon, propertyName);
            setWidthPopUp("50vw");
            setShowCancelButton(true);
        } else if (date.getTime() >= requestDockStartOn.getTime()) {
            onUpdateFormProperty(date, propertyName);
        } else {
            setWarningValidateInspectorArrive(
                t("INFO.INVALID_INSPECTION_TIME", {
                    dockStartOn: convertToLocal(requestDockStartOn, FormatDate.DATE_HOUR_MINUTE),
                    interpolation: { escapeValue: false },
                    timeWithOrganization: t("FIELDS.HOUR_INSPECTOR_ARRIVED_ON"),
                }),
            );

            if (requestDockStartOn) {
                const hours = requestDockStartOn.getHours();
                const minutes = requestDockStartOn.getMinutes();
                date.setHours(hours);
                date.setMinutes(minutes);
            }
            
            onUpdateFormProperty(date, propertyName);

            setWidthPopUp("50vw");
            setShowCancelButton(false);
        }
    };

    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(value, name);
        },
        [dispatch],
    );

    useEffect(() => {
        !organismTypes.length && dispatch(fetchOrganisms());
        if (form.organismId) {
            const organism = allOrganisms.find((item) => item.id === form.organismId);
            dispatch(setCertificatesByOrganism(organism ? organism.certificates : []));
        }
    }, [form.organismId, organismTypes]);

    useEffect(() => {
        if (!requiredDocumentNumber && showPopUp === PopUpCodes.CREATE && !form.id) {
            onUpdateFormProperty("", compileNameOfProperty<IInspection>("certificateNumber"));
        }
    }, [requiredDocumentNumber]);

    return (
        <div className={` ${styles.generalContainer} ${hasVerticalScroll ? styles.spaceWithScroll : ""}`}>
            <div className={styles.form}>
                <div className={styles.row}>
                    <Selector
                        selectedKey={form.organismId}
                        name={compileNameOfProperty<IInspection>("organismId")}
                        onChange={onSelectorChange}
                        options={useMemo(() => mapIDescriptionToDropDownOptions(organismTypes), [organismTypes])}
                        title={t("FIELDS.ORGANISMDESCRIPTION")}
                        required={schema.inspection_organismId.required}
                        disabled={form.id ? schema.inspection_organismId.readonly : false}
                    />
                </div>
                <div className={styles.row}>
                    <Selector
                        selectedKey={form.certificateTypeId}
                        name={compileNameOfProperty<IInspection>("certificateTypeId")}
                        onChange={onSelectorChange}
                        options={useMemo(() => mapIDescriptionToDropDownOptions(certificateTypes), [certificateTypes])}
                        title={t("FIELDS.CERTIFICATETYPEDESCRIPTION")}
                        required={schema.inspection_certificateTypeId.required}
                        disabled={form.id ? schema.inspection_certificateTypeId.readonly : false}
                    />
                    <FormField
                        type="text"
                        value={form.certificateNumber}
                        label={t("FIELDS.CERTIFICATENUMBER")}
                        isRequired={requiredDocumentNumber}
                        error={errors.certificateNumber}
                        {...register(compileNameOfProperty<IInspection>("certificateNumber"), {
                            onChange: onInputChange,
                            value: form.certificateNumber,
                        })}
                        disabled={disabledDocumentNumber}
                    />
                </div>
                <div className={styles.row}>
                    <FormField
                        type="text"
                        value={form.exporter}
                        label={t("FIELDS.EXPORTER")}
                        isRequired={schema.inspection_exporter.required}
                        error={errors.exporter}
                        {...register(compileNameOfProperty<IInspection>("exporter"), {
                            onChange: onInputChange,
                            value: form.exporter,
                            required: schema.inspection_exporter.required,
                        })}
                        disabled={schema.inspection_exporter.readonly}
                    />

                    <FormField
                        type="text"
                        value={form.importer}
                        label={t("FIELDS.IMPORTER")}
                        isRequired={schema.inspection_importer.required}
                        error={errors.importer}
                        {...register(compileNameOfProperty<IInspection>("importer"), {
                            onChange: onInputChange,
                            value: form.importer,
                            required: schema.inspection_importer.required,
                        })}
                        disabled={schema.inspection_importer.readonly}
                    />
                </div>

                <div className={styles.row}>
                    <div className={styles.yearContainer}>
                        <FormField
                            type="text"
                            value={String(form.year)}
                            label={t("FIELDS.YEAR")}
                            isRequired={schema.inspection_year.required}
                            error={errors.year}
                            {...register(compileNameOfProperty<IInspection>("year"), {
                                onChange: onInputChange,
                                required: schema.inspection_year.required,
                            })}
                            disabled={form.actuations.length >= 1 || schema.inspection_year.readonly}
                            maxLength={4}
                        />
                    </div>

                    {showPopUp === PopUpCodes.UPDATE && schema.inspection_inspectorArrivedOn.visible && (
                        <div className={styles.timeContainer}>
                            <DateTimePickerComponent
                                minDate={requestDockStartOn}
                                name={compileNameOfProperty<IInspection>("inspectorArrivedOn")}
                                label={t("FIELDS.HOUR_INSPECTOR_ARRIVED_ON")}
                                onChange={onDateChange}
                                initValue={form.inspectorArrivedOn ? new Date(convertToLocal(form.inspectorArrivedOn, FormatDate.FULL)) : undefined}
                                disabled={schema.inspection_inspectorArrivedOn.readonly}
                            />
                        </div>
                    )}
                    {showPopUp === PopUpCodes.UPDATE && schema.inspection_inspectorArrivedOn.visible && (
                        <div className={styles.manualContainer}>
                            <ControlledRadioButtonGroup
                                selectedKey={String(+form.manually)}
                                onRadioButtonChange={onRadioButtonChange}
                                rules={{
                                    required: true,
                                }}
                                name={compileNameOfProperty<IInspection>("manually")}
                                control={control}
                                label={t("FIELDS.MANUALLY")}
                            />
                        </div>
                    )}
                </div>
                {form.hasVua && (
                    <div className={styles.lastRow}>
                        <FormField
                            name={t("FIELDS.VUA")}
                            type="text"
                            value={form.vuaOrganismCode}
                            label={t("FIELDS.VUA")}
                            onChange={() => null}
                            disabled={true}
                        />
                        <FormField
                            name={t("FIELDS.MRN_DUA")}
                            type="text"
                            value={form.duaMrnNumber}
                            label={t("FIELDS.MRN_DUA")}
                            onChange={() => null}
                            disabled={true}
                        />
                        <FormField
                            name={t("FIELDS.TARIFF_DUA")}
                            type="text"
                            value={form.duaItemNumber}
                            label={t("FIELDS.TARIFF_DUA")}
                            onChange={() => null}
                            disabled={true}
                        />
                    </div>
                )}
            </div>
            <PopUpConfirmation
                showPopUp={warningValidateInspectorArrive ? true : false}
                onCancel={() => {
                    setWarningValidateInspectorArrive("");
                    setShowCancelButton(false);
                }}
                onAccept={() => {
                    setWarningValidateInspectorArrive("");
                    setShowCancelButton(false);
                }}
                type={PopUpConfirmationType.INFO}
                width={widthPopUp}
                hiddeCancelButon={!showCancelButton}
                message={warningValidateInspectorArrive}
                height={"auto"}
            />
        </div>
    );
};
