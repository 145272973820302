import { createUseStyles } from "react-jss";

export const TimePickerComponentStyle = createUseStyles((theme: any) => ({
    ".MuiFormControl-root": {
        "& .MuiInput-underline:before": {
            borderBottom: "5px solid red",
        },
    },
    containerTimePicker: {
        display: "flex",
        alignContent: "center",
        "& .MuiInput-root": {
            fontSize: theme.fonts.sizes.base,
            color: theme.colors.table.body.color,
        },
        "& .MuiInput-root:hover:before": {
            cursor: "pointer !important",
            pointerEvents: "all",
        },
        "& .MuiFormLabel-root": {
            fontSize: "0.90rem",
            "& > .MuiInput-underline:before": {
                borderBottom: "5px solid #82868B",
            },
        },
        "& .MuiInput-underline:before": {
            borderBottom: "5px solid #82868B",
        },
        "& .MuiInput-root:after": {
            borderBottom: "1px solid #7367f0 !important",
        },
        "& .MuiInput-root > input": {
            color: theme.colors.table.body.color,
            "& .MuiInput-underline:before": {
                borderBottom: "2px solid #82868B",
            },
        },
    },
    label: (props: any) => ({
        "& div > input": {
            color: props.hasError ? theme.colors.font.error : theme.colors.form.input.default,
            fontSize: "0.90rem",
        },
        "& .MuiFormLabel-asterisk": {
            color: theme.colors.palette.red,
        },
        "& .MuiInputLabel-shrink": {
            fontSize: "0.75rem !important",
            color: "#82868B",
            transform: "translate(0, 5px)",
        },
        "& .MuiInputLabel-root": {
            fontSize: "0.90rem",
            "& .MuiInput-underline:before": {
                borderBottom: "5px solid #82868B",
            },
        },
    }),
    timePicker: {
        zIndex: "2000000 !important",
    },
    inputLabel: {
        fontFamily: "'Montserrat', -apple-system, BlinkMacSystemFont, Helvetica, Arial, serif !important",
        paddingBottom: -5,
    },
    inputTime: {
        padding: "5.5px 0px",
        "&::-webkit-calendar-picker-indicator": {
            display: "none",
        },
        "&::-moz-focus-inner": {
            border: 0,
        },
        "&::-ms-clear": {
            display: "none",
        },
    },
}));
