import { DirectionalHint, IColumn, IDropdownOption, TooltipDelay, TooltipHost } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router";
import { NameIcons } from "../../../../../assets/icons";
import OrganicProduct from "../../../../../assets/icons/eu-organic-logo.png";
import Export from "../../../../../assets/icons/export.svg";
import Fumigated from "../../../../../assets/icons/fumigated.png";
import Halal from "../../../../../assets/icons/halal.png";
import Information from "../../../../../assets/icons/information.svg";
import { routeUrls } from "../../../../../common/constants";
import { routeFeatures } from "../../../../../common/constants/routeFeatures";
import { RoleType } from "../../../../../common/enum";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { ActuationTypes } from "../../../../../common/enum/request/ActuationTypes";
import { ValuedType } from "../../../../../common/enum/request/ValuedTypes";
import { useRoleType } from "../../../../../hooks/role/useRoleType";
import { IRole } from "../../../../../models/common/IRole";
import { IRequestDetail, IRequestDetailWithIndex } from "../../../../../models/requests/IRequestDetail";
import { fetchRequestStates } from "../../../../../redux/actions/catalog/catalog";
import { fetchRequestListAction, patchRequestState } from "../../../../../redux/actions/request/list/requestList";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
    setShowAllinspections,
    updateAllChangeDate,
    updateChangeDate,
    updateSortColumn,
} from "../../../../../redux/reducers/request/list/requestlist";
import { getUserRole } from "../../../../../redux/selectors/user/role/role";
import { store } from "../../../../../redux/store";
import { getOrganimsRoles, getPCFUsersRoles } from "../../../../../services/user/roleService";
import { compileNameOfProperty, mapIDescriptionToDropdownOption, onUpdateSortedColumnUtil } from "../../../../../utils";
import { convertToLocal } from "../../../../../utils/dateUtils";
import { Checkboxs } from "../../../../common/checkbox/checkbox";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Selector } from "../../../../common/selectors/selector";
import { ExpedientsColumnComponent } from "../../../common/columns/components/expedientsColumn";
import { RequestLastActuation } from "../../../common/columns/components/lastActuationColumn";
import { RequestListColumnsStyle } from "../../../common/columns/requestListColumnsStyle.jss";
import { EditDraft } from "../../../common/columns/utils/editDraft";
import {
    LetterTurnDescription,
    Letters,
    RequestInspections,
    RequestOrganisms,
} from "../../../common/columns/utils/requestPartials/requestListPartials";
import { RequestSchema } from "../../../common/columns/utils/schema";

export const RequestColumns = (featureId: string, isCustomer: boolean, isVisibleRedRow: boolean, isVisibleGreenRow: boolean): IColumn[] => {
    const { t } = useTranslation();
    const { impersonatedOrganismId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const styles = RequestListColumnsStyle({ theme });
    const dispatch = useAppDispatch();
    const [isIndeterminate, setIndeterminate] = useState<boolean>(false);
    const [isChecked, setChecked] = useState<boolean>(false);
    const userRole: IRole = useSelector(getUserRole);
    const requestStates = useAppSelector((store) => store.catalog.requestStates.data);
    const { sort, showAllInspections } = useAppSelector((state) => state.requestlist);

    const isUserPCF = useRoleType(getPCFUsersRoles());
    const isOrganism = useRoleType(getOrganimsRoles());

    useEffect(() => {
        if (requestStates === undefined) {
            dispatch(fetchRequestStates());
        }
    }, [dispatch, requestStates]);

    const onEditRequest = (id: string | undefined) => {
        let path = "";
        if (impersonatedOrganismId) {
            path = generatePath(routeUrls.ORGANISM_EDIT_REQUEST, { impersonatedOrganismId: impersonatedOrganismId, id: id });
        } else {
            path = generatePath(routeUrls.EDIT_REQUEST, { id });
        }

        navigate(path);
    };

    const onUpdateSortedColumn = useCallback(
        (column: string[]) => () => {
            const { sort, pagination } = store.getState().requestlist;
            let payload = onUpdateSortedColumnUtil(column, sort);
            dispatch(updateSortColumn(payload));
            dispatch(fetchRequestListAction(pagination.currentPage));
        },
        [dispatch],
    );

    const onUpdateAllChangeDate = useCallback(
        () => () => {
            dispatch(updateAllChangeDate(!isChecked));
            checksBoxItems();
        },
        [dispatch, isChecked],
    );

    const onUpdateChangeDate = useCallback(
        (item: IRequestDetailWithIndex, index) => (_event: any) => {
            const payload: IRequestDetailWithIndex = {
                index,
                changeDate: !item.changeDate,
            };
            dispatch(updateChangeDate(payload));
            checksBoxItems();
        },
        [dispatch],
    );

    const checksBoxItems = () => {
        const { list } = store.getState().requestlist;
        const requestSelected = list.filter((item) => item.changeDate === true).length;
        if (requestSelected === 0) {
            setIndeterminate(false);
            setChecked(false);
        }

        if (requestSelected > 0 && requestSelected < list.length) {
            setIndeterminate(true);
            setChecked(false);
        }

        if (requestSelected === list.length) {
            setIndeterminate(false);
            setChecked(true);
        }
    };

    const onStateChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption) => {
        const index: number = +(event.target as HTMLDivElement).id;
        const stateId: number = +option.key;
        const request = store.getState().requestlist.list[index];

        if (request && request.stateId && request.stateId !== stateId) {
            const requestId: number = +request.id!;
            dispatch(patchRequestState(requestId, stateId, index, request.stateId));
        }
    };

    const colorCellUtiTransportNumber = (item: IRequestDetail) => {
        if (item.greenRow && isVisibleGreenRow) {
            return styles.greenRow;
        } else if (isVisibleRedRow && item.redRow && isCustomer) {
            return styles.redRow;
        }
        return "";
    };

    const renderExclamationIcon = (item: IRequestDetail) => {
        let isVisibleRedRowExclamationIcon = isVisibleRedRow;
        let isRedRow = item.redRow;

        if (item.lastActuation !== null && isOrganism && item.lastActuation?.actuationTypeCode === ActuationTypes.PENDING) {
            isVisibleRedRowExclamationIcon = true;
            isRedRow = true;
        }

        if (!isUserPCF && !isOrganism && !isCustomer) {
            return <></>;
        }

        if (!isVisibleRedRowExclamationIcon || !isRedRow) {
            return <></>;
        }

        const keyPrefix = isCustomer ? "CUSTOMER" : isOrganism ? "ORGANISM" : isUserPCF ? "USER_PCF" : "";

        if (keyPrefix === "") {
            return <></>;
        }

        return (
            <Letters
                message={t(`LIST.TOOLTIP_DESCRIPTION.${keyPrefix}.RED_ROW`)}
                letter="!"
                classStyle={styles.exclamation}
                visible={isVisibleRedRowExclamationIcon || item.redRow}
            />
        );
    };

    const renderTransportUnitNumber = (item: IRequestDetail) => {
        const rowIsGreen = item.greenRow && isVisibleGreenRow;
        if (isUserPCF && rowIsGreen) {
            return (
                <TooltipHost
                    content={t("LIST.TOOLTIP_DESCRIPTION.TRANSPORT_UNIT_NUMBER")}
                    children={
                        <div className={`${styles.transportUnitNumberTitle}  ${colorCellUtiTransportNumber(item)}`}>{item.transportUnitNumber}</div>
                    }
                />
            );
        }

        return <div className={`${styles.transportUnitNumberTitle}  ${colorCellUtiTransportNumber(item)}`}>{item.transportUnitNumber}</div>;
    };

    const colorCellSort = (item: IRequestDetail) => {
        if (item.representativePresenceTypeDescription && item.representativePresenceTypeDescription?.toLowerCase() === "no") {
            return styles.orangeCell;
        } else if (isVisibleRedRow && item.redRow && isCustomer) {
            return styles.redRow;
        }
        return "";
    };

    const renderSortCell = (item: IRequestDetail) => {
        const inspectionPresence = item.representativePresenceTypeDescription && item.representativePresenceTypeDescription?.toLowerCase() === "no";
        if (isUserPCF && inspectionPresence) {
            return (
                <TooltipHost
                    content={t("LIST.TOOLTIP_DESCRIPTION.SORT_CELL")}
                    children={<span className={colorCellSort(item)}>{item.sort}</span>}
                />
            );
        }

        return <span className={colorCellSort(item)}>{item.sort}</span>;
    };

    const renderValuedCell = (valued: string) => {
        const keyPrefix = isCustomer ? "CUSTOMER" : isUserPCF ? "USER_PCF" : null;
        if (!keyPrefix) return <>{valued}</>;

        let content = "";

        if (valued === ValuedType.YES) {
            content = t(`LIST.TOOLTIP_DESCRIPTION.${keyPrefix}.VALUED_YES`);
        } else if (valued === ValuedType.DRAFT) {
            content = t(`LIST.TOOLTIP_DESCRIPTION.${keyPrefix}.VALUED_DRAFT`);
        } else if (valued === ValuedType.NO) {
            content = t(`LIST.TOOLTIP_DESCRIPTION.${keyPrefix}.VALUED_NO`);
        } else if (valued === ValuedType.VALIDATE) {
            content = t(`LIST.TOOLTIP_DESCRIPTION.${keyPrefix}.VALUED_VALIDATE`);
        }

        return (
            <TooltipHost content={content}>
                <>{valued}</>
            </TooltipHost>
        );
    };

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IRequestDetail>("changeDate"),
            minWidth: 25,
            maxWidth: 25,
            name: "",
            onRenderHeader: () => (
                <div className={styles.selectAllRequest}>
                    <Checkboxs
                        isChecked={isChecked}
                        isIndeterminate={isIndeterminate}
                        handleChange={onUpdateAllChangeDate()}
                    />
                </div>
            ),
            onRender: (item: IRequestDetailWithIndex, index) => (
                <Checkboxs
                    isChecked={item.changeDate ? item.changeDate : false}
                    name={item.id}
                    handleChange={onUpdateChangeDate(item, index)}
                />
            ),
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("date"),
            minWidth: 62,
            maxWidth: 65,
            name: t("LIST.NAME.DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("date")}
                    title={t("LIST.NAME.DATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("date")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <span className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                    {item.date ? convertToLocal(item.date.toString(), FormatDate.DATE) : null}
                </span>
            ),
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("sequentialSort"),
            minWidth: 1,
            maxWidth: 3,
            name: "",
            className: styles.secuencialSort,
            onRender: (_item: IRequestDetail, index) => {
                const { pagination } = store.getState().requestlist;
                return <span>{pagination.firstItemNumber + Number(index)}</span>;
            },
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("sort"),
            minWidth: 60,
            maxWidth: 90,
            name: t("LIST.NAME.SORT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("sort")}
                    title={t("LIST.NAME.SORT")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("sort")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => renderSortCell(item),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("transportUnitDescription"),
            minWidth: 75,
            maxWidth: 150,
            name: t("LIST.NAME.TRANSPORT_UNIT_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("transportUnitDescription")}
                    title={t("LIST.NAME.TRANSPORT_UNIT_TYPE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("transportUnitDescription")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <span className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>{item.transportUnitDescription}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("transportUnitSizeCode"),
            minWidth: 44,
            maxWidth: 60,
            name: t("LIST.NAME.CONTAINER_SIZE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("transportUnitSizeCode")}
                    title={t("LIST.NAME.CONTAINER_SIZE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("transportUnitSizeCode")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <span className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>{item.transportUnitSizeCode}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("transportUnitCode"),
            minWidth: 30,
            maxWidth: 50,
            name: t("LIST.NAME.TRANSPORT_UNIT_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("transportUnitNumber")}
                    title={t("LIST.NAME.TRANSPORT_UNIT_TYPE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("transportUnitNumber")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <span className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>{item.transportUnitCode}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("transportUnitNumber"),
            minWidth: 140,
            maxWidth: 150,
            name: t("LIST.NAME.TRANSPORT_UNIT_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("transportUnitNumber")}
                    title={t("LIST.NAME.TRANSPORT_UNIT_NUMBER")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("transportUnitNumber")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <>
                    {renderTransportUnitNumber(item)}

                    <div className={styles.transportUnitInfo}>
                        {item.fumigated || item.dryCharge ? (
                            <TooltipHost content={t("LIST.NAME.FUMIGATED")}>
                                <img
                                    src={Fumigated}
                                    className={styles.iconImg}
                                    alt={Fumigated}
                                />
                            </TooltipHost>
                        ) : null}
                        {item.export ? (
                            <TooltipHost content={t("LIST.NAME.EXPORT")}>
                                <img
                                    src={Export}
                                    className={styles.iconImg}
                                    alt={Export}
                                />
                            </TooltipHost>
                        ) : null}
                        {item.remarks ? (
                            <TooltipHost content={item.remarks}>
                                <img
                                    src={Information}
                                    className={styles.iconImg}
                                    alt={Information}
                                />
                            </TooltipHost>
                        ) : null}
                        {item.halalCertified ? (
                            <TooltipHost content={t("LIST.NAME.HALAL")}>
                                <img
                                    src={Halal}
                                    className={styles.iconImg}
                                    alt={Halal}
                                />
                            </TooltipHost>
                        ) : null}
                        {item.organicProduct ? (
                            <TooltipHost content={t("LIST.NAME.ORGANIC_PRODUCT")}>
                                <img
                                    src={OrganicProduct}
                                    className={styles.iconImg}
                                    alt={OrganicProduct}
                                />
                            </TooltipHost>
                        ) : null}
                        <Letters
                            message={t("LIST.NAME.ALERT")}
                            letter="(A)"
                            classStyle={styles.aLetter}
                            visible={item.alert ?? false}
                        />
                        <Letters
                            message={t("LIST.NAME.FORCED_DATE")}
                            letter="(D)"
                            classStyle={styles.dLetter}
                            visible={item.forcedDate ?? false}
                        />
                        <Letters
                            message={t("LIST.NAME.VUA")}
                            letter="(V)"
                            classStyle={styles.vLetter}
                            visible={item.vua || false}
                        />
                        <Letters
                            message={t("LIST.TOOLTIP_DESCRIPTION.DSA")}
                            letter="DSA"
                            classStyle={styles.dsaLetter}
                            visible={item.summaryActive ?? false}
                        />
                        <LetterTurnDescription
                            letter={item.turnCode ?? undefined}
                            classStyle={!item.appointmentAssigned ? styles.turnLetter : styles.turnLetterAppointment}
                            appointmentAssigned={item.appointmentAssigned}
                            visible={item.turnCode ? true : false}
                        />
                        <Letters
                            message={t("LIST.TOOLTIP_DESCRIPTION.APP")}
                            letter="APP"
                            classStyle={styles.appLetter}
                            visible={item.app ?? false}
                        />
                        {renderExclamationIcon(item)}
                    </div>
                </>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("description"),
            minWidth: 87,
            maxWidth: 250,
            name: t("LIST.NAME.DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("description")}
                    title={t("LIST.NAME.DESCRIPTION")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("description")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={item.redRow && isVisibleRedRow ? styles.redRow : ""}>
                    <TooltipHost
                        directionalHint={DirectionalHint.topLeftEdge}
                        delay={TooltipDelay.zero}
                        content={item.description}
                    >
                        {item.description}
                    </TooltipHost>
                </div>
            ),

            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("valued"),
            minWidth: 80,
            maxWidth: 90,
            name: t("LIST.NAME.VALUED"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("valued")}
                    title={t("LIST.NAME.VALUED")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("valued")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => {
                return renderValuedCell(item.valued ?? "");
            },
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("customerName"),
            minWidth: 62,
            maxWidth: 150,
            name: t("LIST.NAME.CUSTOMER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("customerName")}
                    title={t("LIST.NAME.CUSTOMER")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("customerName")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>{item.customerName}</div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("stateId"),
            minWidth: 150,
            maxWidth: 180,
            name: t("LIST.NAME.STATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("stateCode")}
                    title={t("LIST.NAME.STATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([
                        compileNameOfProperty<IRequestDetail>("stateCode"),
                        compileNameOfProperty<IRequestDetail>("stateOn"),
                    ])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail, index) =>
                requestStates?.find((obj) => obj.id === item.stateId && !obj.internal && !obj.last) ? (
                    <>
                        <Selector
                            id={index?.toString()}
                            name=""
                            title=""
                            options={requestStates.filter((obj) => !obj.internal).map(mapIDescriptionToDropdownOption)}
                            selectedKey={item.stateId}
                            onChange={onStateChange}
                            className={styles.stateSelector}
                        />
                    </>
                ) : (
                    <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                        {requestStates?.find((obj) => obj.id === item.stateId)?.description}
                    </div>
                ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("stateDescription"),
            minWidth: 63,
            maxWidth: 150,
            name: t("LIST.NAME.STATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("stateCode")}
                    title={t("LIST.NAME.STATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([
                        compileNameOfProperty<IRequestDetail>("stateCode"),
                        compileNameOfProperty<IRequestDetail>("stateOn"),
                    ])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>{item.stateDescription}</div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("customerReference"),
            minWidth: 90,
            maxWidth: 150,
            name: t("LIST.NAME.CUSTOMER_REFERENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("customerReference")}
                    title={t("LIST.NAME.CUSTOMER_REFERENCE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("customerReference")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>{item.customerReference}</div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("dockDescription"),
            minWidth: 100,
            maxWidth: 150,
            name: t("LIST.NAME.DOCK"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("dockDescription")}
                    title={t("LIST.NAME.DOCK")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("dockDescription")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>{item.dockDescription}</div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("merchandiseTypeCode"),
            minWidth: 95,
            maxWidth: 150,
            name: t("LIST.NAME.MERCHANDISE_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("merchandiseTypeCode")}
                    title={t("LIST.NAME.MERCHANDISE_TYPE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("merchandiseTypeCode")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>{item.merchandiseTypeCode}</div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("inspectionPresence"),
            minWidth: 80,
            maxWidth: 150,
            name: t("LIST.NAME.INSPECTION_PRESENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("inspectionPresence")}
                    title={t("LIST.NAME.INSPECTION_PRESENCE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("inspectionPresence")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>{item.inspectionPresence}</div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("dockAssignmentOn"),
            minWidth: 80,
            maxWidth: 150,
            name: t("LIST.NAME.DOCK_ASSIGNMENT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("dockAssignmentOn")}
                    title={t("LIST.NAME.DOCK_ASSIGNMENT")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("dockAssignmentOn")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                    {item.dockAssignmentOn ? convertToLocal(item.dockAssignmentOn.toString(), FormatDate.HOUR_MINUTE) : null}
                </div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("entryOn"),
            minWidth: 55,
            maxWidth: 100,
            name: t("LIST.NAME.ENTRY_ON"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("entryOn")}
                    title={t("LIST.NAME.ENTRY_ON")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("entryOn")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                    {item.entryOn ? convertToLocal(item.entryOn.toString(), FormatDate.HOUR_MINUTE) : null}
                </div>
            ),
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("dockStartOn"),
            minWidth: 75,
            maxWidth: 150,
            name: t("LIST.NAME.DOCK_START_ON"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("dockStartOn")}
                    title={t("LIST.NAME.DOCK_START_ON")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("dockStartOn")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                    {item.dockStartOn ? convertToLocal(item.dockStartOn.toString(), FormatDate.HOUR_MINUTE) : null}
                </div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("dockEndOn"),
            minWidth: 73,
            maxWidth: 150,
            name: t("LIST.NAME.DOCK_END_ON"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("dockEndOn")}
                    title={t("LIST.NAME.DOCK_END_ON")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("dockEndOn")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                    {item.dockEndOn ? convertToLocal(item.dockEndOn.toString(), FormatDate.HOUR_MINUTE) : null}
                </div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("exitOn"),
            minWidth: 55,
            maxWidth: 150,
            name: t("LIST.NAME.EXIT_ON"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("exitOn")}
                    title={t("LIST.NAME.EXIT_ON")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("exitOn")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                    {item.exitOn ? convertToLocal(item.exitOn.toString(), FormatDate.HOUR_MINUTE) : null}
                </div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("stateOn"),
            minWidth: 55,
            maxWidth: 150,
            name: t("LIST.NAME.HOUR"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("stateOn")}
                    title={t("LIST.NAME.HOUR")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("stateOn")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <span className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                    {item.stateOn ? convertToLocal(item.stateOn.toString(), FormatDate.HOUR_MINUTE) : null}
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("documentsNumber"),
            minWidth: 20,
            maxWidth: 30,
            name: t("LIST.NAME.DOCUMENTS_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("documentsNumber")}
                    iconTitle={
                        <CustomIcons
                            size={"1.10rem"}
                            disabledSelect={true}
                            iconName={"Documentation"}
                            margin={"0px"}
                        />
                    }
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("documentsNumber")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={`${styles.documentsNumber} ${isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}`}>
                    {item.documentsNumber}
                </div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("organisms"),
            minWidth: 65,
            maxWidth: 85,
            name: t("LIST.NAME.ORGANISMS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("organisms")}
                    title={t("LIST.NAME.ORGANISMS")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("organisms")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <span className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                    <RequestOrganisms requestOrganisms={item.organisms} />
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("lastActuation"),
            minWidth: 40,
            maxWidth: 150,
            name: t("LIST.NAME.LAST_ACTUATION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("lastActuation")}
                    title={t("LIST.NAME.LAST_ACTUATION")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("lastActuation")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <span className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                    <RequestLastActuation lastActuation={item.lastActuation} />
                </span>
            ),
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IRequestDetail>("expedients"),
            minWidth: 400,
            maxWidth: 600,
            name: t("LIST.NAME.EXPEDIENTS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("expedients")}
                    title={t("LIST.NAME.EXPEDIENTS")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("expedients")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <span className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                    <ExpedientsColumnComponent
                        requestExpedient={item.expedients}
                        requestId={Number.parseInt(item.id || "")}
                        styles={styles}
                    />
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("appointmentTimeZone"),
            minWidth: 85,
            maxWidth: 150,
            name: t("LIST.NAME.APPOINTMENT_TIME_ZONE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("appointmentTimeZone")}
                    title={t("LIST.NAME.APPOINTMENT_TIME_ZONE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("appointmentTimeZone")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>{item.appointmentTimeZone}</div>
            ),
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IRequestDetail>("inspections"),
            minWidth: 230,
            maxWidth: 300,
            name: t("LIST.NAME.INSPECTIONS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("inspections")}
                    iconTitle={
                        <CustomIcons
                            margin={"10px"}
                            iconName={!showAllInspections ? "Add" : "Remove"}
                            onClick={() => {
                                dispatch(setShowAllinspections(!showAllInspections));
                            }}
                        ></CustomIcons>
                    }
                    title={t("LIST.NAME.INSPECTIONS")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("inspections")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <span className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                    <RequestInspections
                        requestInspections={item.inspections}
                        showActuationGroup={userRole.id === RoleType.CUSTOMER_ADMIN || userRole.id === RoleType.CUSTOMER_USER}
                        styles={styles}
                    />
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("inspectionWithoutActuations"),
            minWidth: 230,
            maxWidth: 300,
            name: t("LIST.NAME.INSPECTIONS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("inspectionWithoutActuations")}
                    iconTitle={
                        <CustomIcons
                            margin={"10px"}
                            iconName={!showAllInspections ? "Add" : "Remove"}
                            onClick={() => {
                                dispatch(setShowAllinspections(!showAllInspections));
                            }}
                        ></CustomIcons>
                    }
                    title={t("LIST.NAME.INSPECTIONS")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("inspections")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <span className={isVisibleRedRow && item.redRow && isCustomer ? styles.redRow : ""}>
                    <RequestInspections
                        requestInspections={item.inspections}
                        showActuationGroup={userRole.id === RoleType.CUSTOMER_ADMIN || userRole.id === RoleType.CUSTOMER_USER}
                        styles={styles}
                        hiddenActuations={true}
                    />
                </span>
            ),
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IRequestDetail>("edit"),
            minWidth: 30,
            maxWidth: 40,
            name: "",
            onRender: (item: IRequestDetail) => {
                return item.groupId ? (
                    <EditDraft groupId={item.groupId || ""} />
                ) : (
                    <CustomIcons
                        margin={"5px"}
                        size={20}
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            onEditRequest(item.id);
                        }}
                    />
                );
            },
            isResizable: false,
        },
    ];

    return RequestSchema(columns, featureId);
};
