import { IComboBoxOption } from "@fluentui/react";
import dayjs from "dayjs";
import { useEffect, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { MaxDays } from "../../../../../../../../../common/constants";
import { FormatDate } from "../../../../../../../../../common/enum/dateTime/FormatDate";
import { ITariffCondition } from "../../../../../../../../../models/management/tariff/ITariffData";
import { IConceptFormTariff } from "../../../../../../../../../models/requests/IEditRequestBilling";
import { fetchManagmentTariffListAction } from "../../../../../../../../../redux/actions/management/tariff/tariff";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../redux/hooks";
import { tariffTypesSelector } from "../../../../../../../../../redux/selectors/catalog/catalogSelector";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../../../utils";
import { convertToLocal } from "../../../../../../../../../utils/dateUtils";
import { ControlledDatePicker } from "../../../../../../../../common/datePicker/controlledDatePicker";
import { Selector } from "../../../../../../../../common/selectors/selector";
import { myResolverConcept } from "./myResolverConcept";
import { NewConceptStyle } from "./formStyle.jss";
import { FormNumberField } from "../../../../../../../../common/numberField/numberField";
import { IGenericPayload } from "../../../../../../../../../models";
import { updateEditRequestInvoiceConceptProperty } from "../../../../../../../../../redux/reducers/request/edit/form/concept";
import { FormatNumberInput } from "../../../../../../../../common/numberField/utils";

export const EditRequestInvoiceConceptTariffForm = () => {
    const theme = useTheme();
    const style = NewConceptStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.FIELDS" });
    const dispatch = useAppDispatch();

    const { form } = useAppSelector((state) => state.editRequestInvoiceConceptForm);

    const { register, control } = useForm<IConceptFormTariff>({ mode: "onChange", resolver: myResolverConcept });

    const minDate = useMemo(() => {
        return dayjs(new Date()).startOf("day").toDate();
    }, []);

    const maxDate = useMemo(() => {
        return dayjs(minDate).add(MaxDays.REQUEST_SUBMITTED, "day").startOf("day").toDate();
    }, [minDate]);

    const conceptTariffsOptions = useAppSelector(tariffTypesSelector);
   

    const dateTariff = useMemo(() => {
        return new Date(form.date ?? "");
    }, [form.date]);

   
    const conceptTariffsComboOptions: IComboBoxOption[] = conceptTariffsOptions.map((item: ITariffCondition) => {
        return {
            key: item.id ? item.id : 0,
            text: item.conceptDescription,
        };
    });
    const onUpdateHeaderProperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };

        dispatch(updateEditRequestInvoiceConceptProperty(payload));
    };

    const setValueAmountAndUnit = () => {
        let concept = conceptTariffsOptions.find((item) => {
            return form.tariffId === item.id;
        });

        if (concept) {
            onUpdateHeaderProperty(compileNameOfProperty<IConceptFormTariff>("unitPrice"), concept.unitPrice);
            onUpdateHeaderProperty(compileNameOfProperty<IConceptFormTariff>("quantity"), 1);
        }
    };

    const onDateChange = (date: any) => {
        onUpdateHeaderProperty(compileNameOfProperty<IConceptFormTariff>("date"), convertToLocal(date, FormatDate.ISO));

    };

    const onSelectorChange = useCallback(
        (event: any, option: any, name: string) => {
            onUpdateHeaderProperty(name, option.key);
        },
        [dispatch],
    );


    const onInputNumberChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateHeaderProperty(name, FormatNumberInput(value));
        },
        [dispatch],
    );


    useEffect(() => {
        if (form.tariffId && !form.id) {
            setValueAmountAndUnit();
        }
    }, [form.tariffId, conceptTariffsOptions]);

    useEffect(() => {
        if (!conceptTariffsOptions.length) {
            dispatch(fetchManagmentTariffListAction());
        }
    }, [conceptTariffsOptions]);

    return (
        <div className={style.form}>
            {form.id && (
                <div className={style.row}>
                    <ControlledDatePicker
                        name={compileNameOfProperty<IConceptFormTariff>("date")}
                        label={t("INVOICE_DATE_OF_MOVEMENT")}
                        onDateChange={onDateChange}
                        initValue={dateTariff}
                        minDate={minDate}
                        control={control}
                        maxDate={maxDate}
                    />
                </div>
            )}

            <div className={style.row}>
                <Selector
                    selectedKey={form.tariffId}
                    name={compileNameOfProperty<IConceptFormTariff>("tariffId")}
                    onChange={onSelectorChange}
                    options={conceptTariffsComboOptions}
                    title={t("TARIFFHEADING")}
                    required={true}
                    disabled={!conceptTariffsComboOptions.length || form.id ? true : false}
                />
            </div>
            <div className={style.row}>
                <FormNumberField
                    value={String(form.tariffId ? form.quantity : "")}
                    label={t("INVOICE_UNITS")}
                    {...register(compileNameOfProperty<IConceptFormTariff>("quantity"), {
                        onChange: onInputNumberChange,
                        value: form.tariffId ? form.quantity : undefined,
                        required: true,
                    })}
                    isRequired={true}
                    disabled={!form.tariffId}
                />

                <FormNumberField
                    value={String(form.tariffId ? form.unitPrice : "")}
                    label={t("INVOICE_IMPORT")}
                    {...register(compileNameOfProperty<IConceptFormTariff>("unitPrice"), {
                        onChange: onInputNumberChange,
                        // value: form.tariffId ? form.unitPrice : undefined,
                        required: true,
                    })}
                    isRequired={true}
                    decimalNumber={2}
                    disabled={!form.tariffId}
                />
            </div>
        </div>
    );
};
