import { IChoiceGroupOption } from "@fluentui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { fetchDocksQueued } from "../../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { RadioButtonGroup } from "../../../../../../common/radioButton/radioButtonGroup/radioButtonGroup";
import { DockAssignerPopUpStyles } from "./dockAssignerPopUp.jss";
import { DirectionType } from "../../../../../../../common/enum/DirectionType";

interface IProps {
    setDockIdOption: (value: any) => any;
}
export const DockAssignerPopUp: React.FC<IProps> = ({ setDockIdOption }) => {
    const theme = useTheme();
    const styles = DockAssignerPopUpStyles({ theme });
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const request = useAppSelector((store) => store.editRequest.headerRequest);
    const docks = useAppSelector((store) => store.catalog.docksQueued).map((item) => {
        return {
            key: item.id.toString(),
            text: item.description,
            onRenderField: (props, render) => {
                return (
                    <div className={styles.tableItem}>
                        <div>{render!(props)}</div>
                        <div>
                            {item.queued === 0
                                ? t("EDIT_REQUEST.DOCKASSIGNER.FREE_DOCK")
                                : `${item.queued} ${t("EDIT_REQUEST.DOCKASSIGNER.IN_QUEUE")}`}
                        </div>
                    </div>
                );
            },
        } as IChoiceGroupOption;
    });

    const onChangeDock = (optionKey?: string, name?: any) => {
        setDockIdOption(Number(optionKey));
    };

    useEffect(() => {
        if (request.merchandiseTypeId !== null && request.merchandiseTypeId !== undefined &&
            request.merchandiseOriginId !== null && request.merchandiseOriginId !== undefined &&
            request.humanConsumption !== null && request.humanConsumption !== undefined) {
            dispatch(fetchDocksQueued(request.merchandiseTypeId, request.merchandiseOriginId, request.humanConsumption));
        }
    }, [dispatch, request.merchandiseTypeId, request.merchandiseOriginId, request.humanConsumption]);
    return (
        <div className={styles.container}>
            {docks.length ? (
                <>
                    <div className={styles.table}>
                        <div className={styles.tableHeader}>
                            <div>{t("EDIT_REQUEST.DOCKASSIGNER.DOCK")}</div>
                            <div>{t("EDIT_REQUEST.DOCKASSIGNER.STATE")}</div>
                        </div>
                        <div className={styles.tableBody}>
                            <RadioButtonGroup
                                selectedKey={request.dockId?.toString()}
                                options={docks}
                                onChange={onChangeDock}
                                direction={DirectionType.vertical}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.noData}>{t("COMMON.NO_DATA")}</div>
                </>
            )}
        </div>
    );
};
