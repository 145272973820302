import { IComboBoxOption, IDropdownOption } from "@fluentui/react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NameIcons } from "../../../../assets/icons";
import { componentsMediaQueries, routeUrls } from "../../../../common/constants";
import { IUserOrganization } from "../../../../models";
import { IUserAuthData } from "../../../../models/user/IUserAuthData";
import { resetStatisticsData } from "../../../../redux/actions/statistics/statistics";
import { setUserOrganization } from "../../../../redux/actions/user/organization/organization";
import { useAppDispatch } from "../../../../redux/hooks";
import { resetCatalog } from "../../../../redux/reducers/catalog/catalog";
import { resetMasterAppointmentBoardList } from "../../../../redux/reducers/masterAppointment/appointmentBoard/list";
import { resetMasterAppointmentDatePermissionList } from "../../../../redux/reducers/masterAppointment/appointmentDatePermission/list";
import { resetMasterAppointmentTypeList } from "../../../../redux/reducers/masterAppointment/appointmentType/list";
import { resetFilter } from "../../../../redux/reducers/request/list/filter";
import { setModules } from "../../../../redux/reducers/user/module/module";
import { setLoadingViewPermission, setViewPermission } from "../../../../redux/reducers/user/role/view";
import { resetUsersByRole } from "../../../../redux/reducers/user/users/usersByRole";
import { getUserOrganization } from "../../../../redux/selectors/user/organization/organization";
import { getUserData } from "../../../../redux/selectors/userData/userData";
import { CustomIcons } from "../../customIcons/customIcons";
import { Selector } from "../selector";
import { OrganizationSelectorStyled } from "./organizationSelector.jss";
import { resetConfigMerchandiseCategoryList } from "../../../../redux/reducers/config/merchandiseCategory/list";
import { resetConfigDockMerchandiseList } from "../../../../redux/reducers/config/dockMerchandise/list";
import { resetConfigDockBlockList } from "../../../../redux/reducers/config/dockBlock/list";
import { resetMasterActuationGroupList } from "../../../../redux/reducers/resources/master/actuationGroup/list";
import { resetMasterActuationTypeList } from "../../../../redux/reducers/resources/master/actuationType/list";
import { resetMasterBlockList } from "../../../../redux/reducers/resources/master/block/list";
import { resetMasterCertificateTypeList } from "../../../../redux/reducers/resources/master/certificateType/list";
import { resetMasterCustomsStatesList } from "../../../../redux/reducers/resources/master/customsStates/list";
import { resetMasterDisplayDocksList } from "../../../../redux/reducers/resources/master/docks/list";
import { resetMasterDocumentTypesList } from "../../../../redux/reducers/resources/master/documentTypes/list";
import { resetMasterFinalDestinationTypesList } from "../../../../redux/reducers/resources/master/finalDestinationTypes/list";
import { resetMasterGroupMerchandiseCategoryList } from "../../../../redux/reducers/resources/master/groupMerchandiseCategory/list";
import { resetMasterMerchandiseCategoryList } from "../../../../redux/reducers/resources/master/merchandiseCategory/list";
import { resetMasterMerchandiseOriginList } from "../../../../redux/reducers/resources/master/merchandiseOrigins/list";
import { resetMasterMerchandiseTypesList } from "../../../../redux/reducers/resources/master/merchandiseTypes/list";
import { resetMasterNotificationTypesList } from "../../../../redux/reducers/resources/master/notificationType/list";
import { resetMasterOrganismList } from "../../../../redux/reducers/resources/master/organism/list";
import { resetMasterPresenceRepresentativeList } from "../../../../redux/reducers/resources/master/representativePresence/list";
import { resetMasterRequestStatesList } from "../../../../redux/reducers/resources/master/requestStates/list";
import { resetMasterSealTypesList } from "../../../../redux/reducers/resources/master/sealTypes/list";
import { resetMasterTurnsList } from "../../../../redux/reducers/resources/master/turns/list";
import { resetOrganismCertificateTypeList } from "../../../../redux/reducers/resources/organismCertificatedType/list";
import { resetOrganismCertificateTypeFilter } from "../../../../redux/reducers/resources/organismCertificatedType/filter";
import { resetConfigOrganismCertificateTypeForm } from "../../../../redux/reducers/resources/organismCertificatedType/form";
import { useMediaQuery } from "react-responsive";
import { resetManagementTariff } from "../../../../redux/reducers/management/tariff/list";
import { resetManagementAuthorizedTransportUnitNumberList } from "../../../../redux/reducers/management/authorizedTransportUnitNumber/list";
import { resetManagementDockDisplay } from "../../../../redux/reducers/management/dockDisplay/dockDisplay";
import { resetManagementIncidenceTypeList } from "../../../../redux/reducers/management/incidenceType/list";
import { resetMenuSettingsByRoleForm } from "../../../../redux/reducers/management/menuSettingsByRole/form";
import { resetManagementNotificationList } from "../../../../redux/reducers/management/notification/list";
import { resetManagementParkingValidationRuleList } from "../../../../redux/reducers/management/parkingValidation/list";
import { resetManagementPositioningDisplay } from "../../../../redux/reducers/management/positioningDisplay/positioningDisplay";
import { resetManagementRepresentationList } from "../../../../redux/reducers/management/representation/list";
import { resetManagementRequestDatePermissionList } from "../../../../redux/reducers/management/requestdatepermission/list";
import { resetManagmentSummaryList } from "../../../../redux/reducers/management/summary/list";
import { resetManagementUserList } from "../../../../redux/reducers/management/user/list";
import { resetManagementUserSignupList } from "../../../../redux/reducers/management/userSignup/list";
import { resetManagementWorkCalendarList } from "../../../../redux/reducers/management/workCalendar/list";
import { resetMasterAppointmentTypeActuationList } from "../../../../redux/reducers/masterAppointment/appointmentTypeActuation/list";
import { resetMasterAppointmentPermissionList } from "../../../../redux/reducers/masterAppointment/appointmentPermission/list";

export const OrganizationSelector = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userData: IUserAuthData = useSelector(getUserData);
    const userOrganization: IUserOrganization = useSelector(getUserOrganization);
    const styles = OrganizationSelectorStyled();
    const isMobile = useMediaQuery({ query: componentsMediaQueries.MOBILE });
    const disabled = userData?.organizations?.length > 1 ? false : true;

    const options: IComboBoxOption[] = userData?.organizations?.map((item: IUserOrganization) => {
        return {
            key: item.id,
            text: item.name,
        };
    });

    const handleOrganizationChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option && userData && userOrganization.id !== option.key) {
            const organization = userData.organizations.find((x) => x.id === option.key);
            if (organization) {
                dispatch(setUserOrganization(organization));
                dispatch(setLoadingViewPermission(false));
                dispatch(setModules([]));
                dispatch(setViewPermission([]));
                dispatch(resetCatalog());
                dispatch(resetUsersByRole());
                dispatch(resetFilter());
                dispatch(resetStatisticsData());
                dispatch(resetManagementAuthorizedTransportUnitNumberList());
                dispatch(resetManagementDockDisplay());
                dispatch(resetManagementIncidenceTypeList());
                dispatch(resetMenuSettingsByRoleForm());
                dispatch(resetManagementNotificationList());
                dispatch(resetManagementParkingValidationRuleList());
                dispatch(resetManagementPositioningDisplay());
                dispatch(resetManagementRepresentationList());
                dispatch(resetManagementRequestDatePermissionList());
                dispatch(resetManagmentSummaryList());
                dispatch(resetManagementTariff());
                dispatch(resetManagementUserList());
                dispatch(resetManagementUserSignupList());
                dispatch(resetManagementWorkCalendarList());
                dispatch(resetMasterOrganismList());
                dispatch(resetMasterCertificateTypeList());
                dispatch(resetMasterMerchandiseOriginList());
                dispatch(resetMasterMerchandiseTypesList());
                dispatch(resetMasterMerchandiseCategoryList());
                dispatch(resetMasterDisplayDocksList());
                dispatch(resetMasterRequestStatesList());
                dispatch(resetMasterDocumentTypesList());
                dispatch(resetMasterFinalDestinationTypesList());
                dispatch(resetMasterPresenceRepresentativeList());
                dispatch(resetMasterSealTypesList());
                dispatch(resetMasterTurnsList());
                dispatch(resetMasterBlockList());
                dispatch(resetMasterActuationGroupList());
                dispatch(resetMasterGroupMerchandiseCategoryList());
                dispatch(resetMasterNotificationTypesList());
                dispatch(resetMasterAppointmentDatePermissionList());
                dispatch(resetMasterAppointmentPermissionList());
                dispatch(resetMasterAppointmentBoardList());
                dispatch(resetMasterAppointmentTypeList());
                dispatch(resetMasterAppointmentTypeActuationList());
                dispatch(resetMasterActuationGroupList());
                dispatch(resetMasterActuationTypeList());
                dispatch(resetMasterCustomsStatesList());
                dispatch(resetConfigMerchandiseCategoryList());
                dispatch(resetConfigDockMerchandiseList());
                dispatch(resetConfigDockBlockList());
                dispatch(resetMasterCustomsStatesList());
                dispatch(resetOrganismCertificateTypeList());
                dispatch(resetConfigOrganismCertificateTypeForm());
                dispatch(resetOrganismCertificateTypeFilter());
                navigate(routeUrls.REQUEST);
            }
        }
    };

    return (
        <>
            <CustomIcons
                iconName={NameIcons.PORTICON}
                size={isMobile ? 25 : 30}
                margin={"0px"}
                disabledSelect={true}
            ></CustomIcons>

            <Selector
                className={styles.selector}
                title={""}
                options={options}
                onChange={handleOrganizationChange}
                selectedKey={userOrganization.id}
                disabled={disabled}
                name={"organizationId"}
            />
        </>
    );
};
