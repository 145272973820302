import { IColumn } from "@fluentui/react";
import { useMemo } from "react";
import { IRequestDetail, ViewType } from "../../../../../models";
import { IUserRoleView } from "../../../../../models/requests/IUserRoleView";
import { useAppSelector } from "../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../utils";

export const RequestSchema = (columns: IColumn[], featureId: string): IColumn[] => {
    const { viewPermission } = useAppSelector((state) => state.userRoleViews);
    const viewRequestPermission: IUserRoleView | undefined = viewPermission.find((feature) => feature.id === featureId);

    const viewConfig = useMemo(() => viewRequestPermission?.views.find((view) => view.type === ViewType.LIST), [viewRequestPermission?.views]);
    const viewCommands = useMemo(() => viewRequestPermission?.views.find((view) => view.type === ViewType.COMMANDS), [viewRequestPermission?.views]);

    const changeDateFieldName = useMemo(() => compileNameOfProperty<IRequestDetail>("changeDate"), []);

    const columnSchema = useMemo(() => {
        const viewChangeDate = viewCommands?.fields?.find((field) => field.name === changeDateFieldName);
        const viewCommandsFilter = viewCommands?.fields?.filter((field) => field.name !== changeDateFieldName) || [];

        let schema = [...(viewConfig?.fields || []), ...viewCommandsFilter];
        if (viewChangeDate) {
            schema.unshift(viewChangeDate);
        }
        return schema;
    }, [viewConfig?.fields, viewCommands?.fields, changeDateFieldName]);

    const viewColumns = useMemo(() => {
        const columnKeysLower = columns.map((column) => column.key.toLowerCase());
        return columnSchema
            .filter((field) => columnKeysLower.includes(field.name.toLowerCase()))
            .map((field) => {
                return columns.find((column) => column.key.toLowerCase() === field.name.toLowerCase());
            })
            .filter((column) => column) as IColumn[];
    }, [columns, columnSchema]);

    return viewColumns;
};
