import React from "react";
import { EditRequestContainer } from "./container";
import { Toastr } from "../../../common/toast/toast";
import { routeUrls } from "../../../../common/constants";
import { store } from "../../../../redux/store";
import { setInvoiceCustomersOptions } from "../../../../redux/reducers/user/users/usersByRole";

const resetEditRequestUtils = () => {
    Toastr().clear(`EditRequestContainer`);
    store.dispatch(setInvoiceCustomersOptions([]));
};

export class EditRequestController extends React.Component {
    componentWillUnmount(): void {
        resetEditRequestUtils();
    }

    render(): React.ReactNode {
        return <EditRequestContainer />;
    }
}

export class EditRequestBackToOrganismController extends React.Component {
    componentWillUnmount(): void {
        resetEditRequestUtils();
    }

    render(): React.ReactNode {
        return <EditRequestContainer backRoute={routeUrls.ORGANISM_REQUEST} />;
    }
}
export class EditRequestBackToFinishedRequestController extends React.Component {
    componentWillUnmount(): void {
        resetEditRequestUtils();
    }

    render(): React.ReactNode {
        return <EditRequestContainer backRoute={routeUrls.FINISHED_REQUESTS} />;
    }
}
