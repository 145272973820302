import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { IStatisticsInspectiondetailedItem } from "../../../../../models/statistics/inspection/IDetailed";
import { useAppSelector } from "../../../../../redux/hooks";
import { convertToLocal } from "../../../../../utils";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { dinamicItemData } from "../../utils/utils";
import { StatisticsInspectionDetailedColumnStyle } from "./columnStyle.jss";
import { OrganismTypes } from "../../../../../common/enum";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const StatisticsincidenceDetailedListColumn = (): IColumn[] => {
    const theme = useTheme();
    const styles = StatisticsInspectionDetailedColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INSPECTION.DETAILED_TABLE" });

    const { organisms } = useAppSelector((store) => store.statiticsInspectionDetailedList.data);

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IStatisticsInspectiondetailedItem>("date"),
            minWidth: 70,
            maxWidth: 100,
            name: t("DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectiondetailedItem>("date")}
                    title={t("DATE")}
                    styles={styles}
                />
            ),
            onRender: (item: IStatisticsInspectiondetailedItem) => (
                <span className={styles.dataCell}>{item.date ? convertToLocal(item.date, FormatDate.DATE): DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectiondetailedItem>("requestsPositionedWithInvoiceConcepts"),
            minWidth: 70,
            maxWidth: 110,
            name: t("TOTAL_INSPECTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectiondetailedItem>("requestsPositionedWithInvoiceConcepts")}
                    title={t("TOTAL_INSPECTION")}
                    styles={styles}
                />
            ),
            onRender: (item: IStatisticsInspectiondetailedItem) => (
                <span className={styles.dataCell}>{item.requestsPositionedWithInvoiceConcepts ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectiondetailedItem>("requests"),
            minWidth: 60,
            maxWidth: 90,
            name: t("REQUEST"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectiondetailedItem>("requests")}
                    title={t("REQUEST")}
                    styles={styles}
                />
            ),
            onRender: (item: IStatisticsInspectiondetailedItem) => <span className={styles.dataCell}>{item.requests ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectiondetailedItem>("incidences"),
            minWidth: 70,
            maxWidth: 90,
            name: t("INCIDENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectiondetailedItem>("incidences")}
                    title={t("INCIDENCE")}
                    styles={styles}
                />
            ),
            onRender: (item: IStatisticsInspectiondetailedItem) => <span className={styles.dataCell}>{item.incidences ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectiondetailedItem>("averageEntryTime"),
            minWidth: 60,
            maxWidth: 70,
            name: t("TIME_ENTRY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectiondetailedItem>("averageEntryTime")}
                    title={t("TIME_ENTRY")}
                    styles={styles}
                />
            ),
            onRender: (item: IStatisticsInspectiondetailedItem) => <span className={styles.dataCell}>{item.averageEntryTime ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectiondetailedItem>("averageInspectionTime"),
            minWidth: 80,
            maxWidth: 100,
            name: t("TIME_INSPECTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectiondetailedItem>("averageInspectionTime")}
                    title={t("TIME_INSPECTION")}
                    styles={styles}
                />
            ),
            onRender: (item: IStatisticsInspectiondetailedItem) => <span className={styles.dataCell}>{item.averageInspectionTime ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectiondetailedItem>("averageExitTime"),
            minWidth: 60,
            maxWidth: 100,
            name: t("TIME_EXIT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectiondetailedItem>("averageExitTime")}
                    title={t("TIME_EXIT")}
                    styles={styles}
                />
            ),
            onRender: (item: IStatisticsInspectiondetailedItem) => <span className={styles.dataCell}>{item.averageExitTime ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectiondetailedItem>("averageTotalTime"),
            minWidth: 90,
            maxWidth: 100,
            name: t("TOTAL_TIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectiondetailedItem>("averageTotalTime")}
                    title={t("TOTAL_TIME")}
                    styles={styles}
                />
            ),
            onRender: (item: IStatisticsInspectiondetailedItem) => <span className={styles.dataCell}>{item.averageTotalTime ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: false,
        },
    ];

    const dinamicOrganismItem = () => {
        for (let organism of organisms) {
            columns.push({
                key: organism.code,
                minWidth: 50,
                maxWidth: 100,
                name: organism.code,
                onRenderHeader: () => (
                    <CustomHeaderColumn
                        name={organism.code}
                        title={dinamicTranslate(organism.code)}
                        styles={styles}
                    />
                ),
                onRender: (item: IStatisticsInspectiondetailedItem) => (
                    <span className={styles.dataCell}>{dinamicItemData(item.organismRequests, organism)}</span>
                ),
                isResizable: false,
            });
        }
        return columns;
    };

const dinamicTranslate = (idOrganims: string) => {
        switch (idOrganims) {
            case OrganismTypes.CUSTOMS:
                return t("ADUANA");
            case OrganismTypes.TAX_CANARY:
                return t("CANARIA");
            case OrganismTypes.ANIMAL_HEALTH:
                return t("ANIMAL");
            case OrganismTypes.FOREIGN_HEALTH:
                return t("EXTERIOR");
            case OrganismTypes.PLANT_HEALTH:
                return t("VEGETAL");
            case OrganismTypes.SOIVRE:
                return t("SOIVRE");
            case OrganismTypes.OTHERS:
                return t("OTHERS");
            case OrganismTypes.PHARMACY:
                return t("PHARMACY");
            case OrganismTypes.FISHING:
                return t("FISHING");

            default:
                return DefaultValues.EMPTY_LIST;
        }
    };

    return dinamicOrganismItem();
};
