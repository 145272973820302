import { CatalogAPI } from "../../../api/catalog";
import { IUserRoleView } from "../../../models/requests/IUserRoleView";
import { throwErrorToastrAsync } from "../../../utils/apiUtils";
import {
    loadingActuationsGroup,
    loadingActuationTypes,
    loadingCertificates,
    loadingCustomStates,
    loadingDocks,
    loadingDocumentTypes,
    loadingFinalDestinations,
    loadingMerchandiseOrigins,
    loadingMerchandiseTypes,
    loadingOrganisms,
    loadingOrganizations,
    loadingRepresentativePresenceTypes,
    loadingRequestStates,
    loadingSealTypes,
    loadingTransportUnits,
    loadingTransportUnitSizes,
    loadingTurns,
    loadingUserPositionTypes,
    loadingValuedTypes,
    setActuationsGroup,
    setActuationTypes,
    setCertificates,
    setCertificatesByOrganism,
    setCustomsStates,
    setDocks,
    setDocksQueued,
    setDocumentTypes,
    setFinalDestinations,
    setIncidenceType,
    setMerchandiseOrigins,
    setMerchandiseTypes,
    setOrganisms,
    setOrganizations,
    setRepresentativePresenceTypes,
    setRequestStates,
    setRoles,
    setSealTypes,
    setTransportUnits,
    setTransportUnitSizes,
    setTurns,
    setUserPositionTypes,
    setValuedTypes,
    setMerchandiseCategoryOptions,
    setMerchandiseCategoryOptionsLoading,
    setMerchandiseCategoryRelationShipOptions,
    setMerchandiseCategoryRelationShipLoading,
    loadingNotificationTypes,
    setNotificationTypes,
    loadingNotificationModes,
    setNotificationModes,
    loadingRequestDayPermissionModes,
    setRequestDayPermissionModes,
    setOrganismRemainingLoading,
    setOrganismRemainingData,
    setCertificateTypesRemainingLoading,
    setCertificateTypesRemainingData,
    setMerchandiseOriginsRemainingLoading,
    setMerchandiseCategoryRemainingLoading,
    setMerchandiseCategoryRemainingData,
    setDocumentsTypesRemainingData,
    setDocumentsTypesRemainingLoading,
    setTypesSealsRemainingData,
    setTypesSealsRemainingLoading,
    setFinalDestinationTypesRemainingLoading,
    setFinalDestinationTypesRemainingData,
    setMerchandiseOriginsRemainingData,
    setRepresentativePresenceTypesRemainingLoading,
    setRepresentativePresenceTypesRemainingData,
    setMasterTurnsRemainingLoading,
    setMasterTurnsRemainingData,
    setMasterRequestStatesRemainingData,
    setMasterRequestStatesRemainingLoading,
    setMerchandiseCategorySortedData,
    setMerchandiseCategorySortedLoading,
    setDockBlockData,
    setDockBlockLoading,
    setActuationGroupRemainingLoading,
    setActuationGroupRemainingData,
    setActuationTypesRemainingData,
    setActuationTypesRemainingLoading,
    setGroupManagementCategoryRemainingData,
    setGroupManagementCategoryRemainingLoading,
    setCustomsStatesRemainingLoading,
    setCustomsStatesRemainingData,
    setMerchandiseCategoryGroupLoading,
    setMerchandiseCategoryGroupData,
    setNotificationTypesRemainingLoading,
    setNotificationTypesRemainingData,
    setMerchandiseTypesWithGroup,
    loadingMerchandiseTypesWithGroup,
    loadingTerminals,
    setTerminals,
    setTransportUnitRemainingData,
    setTransportUnitRemainingLoading,
    setAppointmentTimeZoneLoading,
    setAppointmentTimeZoneData,
    loadingActuationTypesForFilter,
    setActuationTypesForFilter,
    loadingAppointmentDayPermissionTypes,
    setAppointmentDayPermissionTypes,
    setLoadingCertificatesByOrganism,
} from "../../reducers/catalog/catalog";
import { setGlobalLoading } from "../../reducers/common/globalLoading";
import { setLoadingMasterActuationGroupForm } from "../../reducers/resources/master/actuationGroup/form";
import { setLoadingMasterActuationTypeForm } from "../../reducers/resources/master/actuationType/form";
import { setLoadingMasterCertificateTypeForm } from "../../reducers/resources/master/certificateType/form";
import { setLoadingMasterCustomsStatesForm } from "../../reducers/resources/master/customsStates/form";
import { setLoadingMasterDocumentTypesForm } from "../../reducers/resources/master/documentTypes/form";
import { setLoadingMasterFinalDestinationTypesForm } from "../../reducers/resources/master/finalDestinationTypes/form";
import { setLoadingMasterGroupMerchandiseCategoryForm } from "../../reducers/resources/master/groupMerchandiseCategory/form";
import { setLoadingMasterMerchandiseCategoryForm } from "../../reducers/resources/master/merchandiseCategory/form";
import { setLoadingMasterMerchandiseOriginsForm } from "../../reducers/resources/master/merchandiseOrigins/form";
import { setLoadingMasterNotificationTypesForm } from "../../reducers/resources/master/notificationType/form";
import { setLoadingMasterOrganismForm } from "../../reducers/resources/master/organism/form";
import { setLoadingMasterPresenceRepresentativeForm } from "../../reducers/resources/master/representativePresence/form";
import { setLoadingMasterRequestStatesForm } from "../../reducers/resources/master/requestStates/form";
import { setLoadingMasterTypesSealsForm } from "../../reducers/resources/master/sealTypes/form";
import { setLoadingMasterTransportUnitForm } from "../../reducers/resources/master/transportUnit/form";
import { setLoadingMasterTurnsForm } from "../../reducers/resources/master/turns/form";
import { setViewPermissionReceived } from "../../reducers/user/role/view";
import { AppThunk, store } from "../../store";
import { fetchViewsAction } from "../user/role/view";

const catalogAPI = new CatalogAPI();

export const fetchOrganizations = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingOrganizations(true));
        const response = await catalogAPI.getOrganizations();
        dispatch(setOrganizations(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingOrganizations(false));
    }
};

export const fetchFinalDestinations = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingFinalDestinations(true));
        const response = await catalogAPI.getFinalDestinationTypes();
        dispatch(setFinalDestinations(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingFinalDestinations(false));
    }
};

export const fetchCustomStates = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().catalog.customsStates;
        if (loading) return;
        dispatch(loadingCustomStates(true));
        const response = await catalogAPI.getCustomsStateTypes();
        dispatch(setCustomsStates(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingCustomStates(false));
    }
};

export const fetchMerchandiseOrigins = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().catalog.merchandiseOrigins;
        if (loading) return;
        dispatch(loadingMerchandiseOrigins(true));
        const response = await catalogAPI.getMerchandiseOrigins();
        dispatch(setMerchandiseOrigins(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingMerchandiseOrigins(false));
    }
};

export const fetchMerchandiseTypes = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().catalog.merchandiseTypes;
        if (loading) return;
        dispatch(loadingMerchandiseTypes(true));
        const response = await catalogAPI.getMerchandiseTypes();
        dispatch(setMerchandiseTypes(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingMerchandiseTypes(false));
    }
};

export const fetchMerchandiseCategoryTypesWithGroup =
    (id?: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(loadingMerchandiseTypesWithGroup(true));
            const response = await catalogAPI.getMerchandiseCategoryTypesWithGroups(id ?? "");
            dispatch(setMerchandiseTypesWithGroup(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(loadingMerchandiseTypesWithGroup(false));
        }
    };

export const fetchTransportUnits = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().catalog.transportUnits;
        if (loading) return;
        dispatch(loadingTransportUnits(true));
        const response = await catalogAPI.getTransportUnits();
        dispatch(setTransportUnits(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingTransportUnits(false));
    }
};

export const fetchRepresentativePresenceTypes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingRepresentativePresenceTypes(true));
        const response = await catalogAPI.getRepresentativePresenceTypes();
        dispatch(setRepresentativePresenceTypes(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingRepresentativePresenceTypes(false));
    }
};

export const fetchTerminals = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingTerminals(true));
        const response = await catalogAPI.getTerminals();
        dispatch(setTerminals(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingTerminals(false));
    }
};

export const fetchSealTypes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingSealTypes(true));
        const response = await catalogAPI.getSealTypes();
        dispatch(setSealTypes(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingSealTypes(false));
    }
};

export const fetchTurns = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().catalog.turns;
        if (loading) return;
        dispatch(loadingTurns(true));
        const response = await catalogAPI.getTurns();
        dispatch(setTurns(response));
        dispatch(loadingTurns(false));
    } catch (error) {
        throwErrorToastrAsync(error);
        dispatch(loadingTurns(false));
    }
};

export const fetchOrganisms = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().catalog.organisms;
        if (loading) return;
        dispatch(loadingOrganisms(true));
        const response = await catalogAPI.getOrganisms();
        dispatch(setOrganisms(response));
        dispatch(loadingOrganisms(false));
    } catch (error) {
        throwErrorToastrAsync(error);
        dispatch(loadingOrganisms(false));
    }
};

export const fetchCertificatesByOrganism =
    (organismId: number): AppThunk =>
    async (dispatch) => {
        try {
            const { loading } = store.getState().catalog.certificatesByOrganism;
            if (loading) return;
            dispatch(setLoadingCertificatesByOrganism(true));
            const response = await catalogAPI.getCertificateTypesByOrganismId(organismId);
            dispatch(setCertificatesByOrganism(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingCertificatesByOrganism(false));
        }
    };

export const fetchCertificates = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingCertificates(true));
        const response = await catalogAPI.getAllCertificateTypes();
        dispatch(setCertificates(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingCertificates(false));
    }
};

export const fetchTransportUnitSizes = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().catalog.transportUnitSizes;
        if (loading) return;
        dispatch(loadingTransportUnitSizes(true));
        const response = await catalogAPI.getContainerSizes();
        dispatch(setTransportUnitSizes(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingTransportUnitSizes(false));
    }
};

export const fetchActuationTypes = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().catalog.actuationTypes;
        if (loading) return;
        dispatch(loadingActuationTypes(true));
        const response = await catalogAPI.getActuationTypes(false);
        dispatch(setActuationTypes(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingActuationTypes(false));
    }
};

export const fetchActuationTypesForFilter = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().catalog.actuationTypesForFilter;
        if (loading) return;
        dispatch(loadingActuationTypesForFilter(true));
        const response = await catalogAPI.getActuationTypes(true);
        dispatch(setActuationTypesForFilter(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingActuationTypesForFilter(false));
    }
};

export const fetchValuedTypes = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().catalog.valuedTypes;
        if (loading) return;
        dispatch(loadingValuedTypes(true));
        const response = await catalogAPI.getValuedTypes();
        dispatch(setValuedTypes(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingValuedTypes(false));
    }
};

export const fetchDocks = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingDocks(true));
        const response = await catalogAPI.getDocks();
        dispatch(setDocks(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingDocks(false));
    }
};

export const fetchDocksQueued =
    (merchandiseTypeId: number, merchandiseOriginId: number, humanConsumption: boolean): AppThunk =>
    async (dispatch) => {
        try {
            const response = await catalogAPI.getDocksQueued(merchandiseTypeId, merchandiseOriginId, humanConsumption);
            dispatch(setDocksQueued(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        }
    };

export const fetchRequestStates = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().catalog.requestStates;
        if (loading) return;
        dispatch(loadingRequestStates(true));
        const response = await catalogAPI.getRequestStates();
        dispatch(setRequestStates(response));
        dispatch(loadingRequestStates(false));
    } catch (error) {
        throwErrorToastrAsync(error);
        dispatch(loadingRequestStates(false));
    }
};

export const fetchDocumentTypes = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().catalog.documentTypes;
        if (loading) return;
        dispatch(loadingDocumentTypes(true));
        const response = await catalogAPI.getDocumentsType();
        dispatch(setDocumentTypes(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingDocumentTypes(false));
    }
};

export const fetchIncidenceTypes =
    (idOrganism?: number): AppThunk =>
    async (dispatch) => {
        try {
            const response = await catalogAPI.getIncidenceType(idOrganism);
            dispatch(setIncidenceType(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        }
    };

export const fetchInspectionActuationsGroup = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingActuationsGroup(true));
        const response = await catalogAPI.getInspectionActuationsGroup();
        dispatch(setActuationsGroup(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingActuationsGroup(false));
    }
};

export const fetchRoleOptions = (): AppThunk => async (dispatch) => {
    try {
        const response = await catalogAPI.getRoles();
        dispatch(setRoles(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    }
};

export const fetchUserPositionTypes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingUserPositionTypes(true));
        const response = await catalogAPI.getUserPositionTypes();
        dispatch(setUserPositionTypes(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingUserPositionTypes(false));
    }
};

export const fetchMerchandiseCategoriesTypes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setMerchandiseCategoryOptionsLoading(true));
        const response = await catalogAPI.getMerchandiseCategory();
        dispatch(setMerchandiseCategoryOptions(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setMerchandiseCategoryOptionsLoading(false));
    }
};

export const fetchMerchandiseCategoriesSorted = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setMerchandiseCategorySortedLoading(true));
        const response = await catalogAPI.getMerchandiseCategorySorted();
        dispatch(setMerchandiseCategorySortedData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setMerchandiseCategorySortedLoading(false));
    }
};

export const fetchMerchandiseCategoriesRealtionShipTypes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setMerchandiseCategoryRelationShipLoading(true));
        const response = await catalogAPI.getMerchandiseCategoryRelationship();
        dispatch(setMerchandiseCategoryRelationShipOptions(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setMerchandiseCategoryRelationShipLoading(false));
    }
};

export const fetchNotificationTypes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingNotificationTypes(true));
        const response = await catalogAPI.getNotificationTypes();
        dispatch(setNotificationTypes(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingNotificationTypes(false));
    }
};

export const fetchNotificationModes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingNotificationModes(true));
        const response = await catalogAPI.getNotificationModes();
        dispatch(setNotificationModes(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingNotificationModes(false));
    }
};

export const fetchRequestDayPermissionModes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingRequestDayPermissionModes(true));
        const response = await catalogAPI.getRequestDayPermissionTypes();
        dispatch(setRequestDayPermissionModes(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingRequestDayPermissionModes(false));
    }
};

export const fetchAppointmentDayPermissionTypes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingAppointmentDayPermissionTypes(true));
        const response = await catalogAPI.getAppointmentDayPermissionTypes();
        dispatch(setAppointmentDayPermissionTypes(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingAppointmentDayPermissionTypes(false));
    }
};

export const fetchViewsToIdRole =
    (featureId: string, roleId: string, isMAPADirector?: boolean): AppThunk =>
    async (dispatch) => {
        try {
            const { viewPermission, isViewLoaded } = store.getState().userRoleViews;

            dispatch(setGlobalLoading(true));

            if (isViewLoaded || isMAPADirector) {                
                const index = viewPermission.findIndex((view) => view.id === featureId);
                const response = await catalogAPI.getCatalogViews(featureId, roleId);

                let updatedViewPermission = [...viewPermission];

                if (index !== -1) {
                    updatedViewPermission[index] = response;
                } else {
                    updatedViewPermission.push(response);
                }

                dispatch(setViewPermissionReceived(updatedViewPermission));
            } else {
                dispatch(fetchViewsAction(true));
            }
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };

export const fetchOrganismRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setOrganismRemainingLoading(true));
        dispatch(setLoadingMasterOrganismForm(true));

        const response = await catalogAPI.getOrganismsNotConfigure();
        dispatch(setOrganismRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterOrganismForm(false));

        dispatch(setOrganismRemainingLoading(false));
    }
};

export const fetchCertificateTypesRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setCertificateTypesRemainingLoading(true));
        dispatch(setLoadingMasterCertificateTypeForm(true));

        const response = await catalogAPI.getMasterCertificateTypesRemaining();
        dispatch(setCertificateTypesRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterCertificateTypeForm(false));

        dispatch(setCertificateTypesRemainingLoading(false));
    }
};

export const fetchMerchandiseOriginRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setMerchandiseOriginsRemainingLoading(true));
        dispatch(setLoadingMasterMerchandiseOriginsForm(true));

        const response = await catalogAPI.getMasterMerchandiseOriginRemaining();
        dispatch(setMerchandiseOriginsRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterMerchandiseOriginsForm(false));

        dispatch(setMerchandiseOriginsRemainingLoading(false));
    }
};

export const fetchMerchandiseCategoryRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setMerchandiseCategoryRemainingLoading(true));
        dispatch(setLoadingMasterMerchandiseCategoryForm(true));

        const response = await catalogAPI.getMasterMerchandiseCategoryRemaining();
        dispatch(setMerchandiseCategoryRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterMerchandiseCategoryForm(false));

        dispatch(setMerchandiseCategoryRemainingLoading(false));
    }
};

export const fetchDocumentTypesRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setDocumentsTypesRemainingLoading(true));
        dispatch(setLoadingMasterDocumentTypesForm(true));
        const response = await catalogAPI.getMasterDocumentTypesRemaining();
        dispatch(setDocumentsTypesRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterDocumentTypesForm(false));

        dispatch(setDocumentsTypesRemainingLoading(false));
    }
};

export const fetchSealTypesRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setTypesSealsRemainingLoading(true));
        dispatch(setLoadingMasterTypesSealsForm(true));
        const response = await catalogAPI.getMasterSealTypesRemaining();
        dispatch(setTypesSealsRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterTypesSealsForm(false));
        dispatch(setTypesSealsRemainingLoading(false));
    }
};

export const fetchFinalDestinationTypesRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setFinalDestinationTypesRemainingLoading(true));
        dispatch(setLoadingMasterFinalDestinationTypesForm(true));

        const response = await catalogAPI.getMasterFinalDestinationTypesRemaining();
        dispatch(setFinalDestinationTypesRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterFinalDestinationTypesForm(false));
        dispatch(setFinalDestinationTypesRemainingLoading(false));
    }
};

export const fetchFinalPresenceRepresentativeTypesRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setRepresentativePresenceTypesRemainingLoading(true));
        dispatch(setLoadingMasterPresenceRepresentativeForm(true));

        const response = await catalogAPI.getMasterPresenceRepresentativeTypesRemaining();
        dispatch(setRepresentativePresenceTypesRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setRepresentativePresenceTypesRemainingLoading(false));
        dispatch(setLoadingMasterPresenceRepresentativeForm(false));
    }
};

export const fetchMasterTurnsRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setMasterTurnsRemainingLoading(true));
        dispatch(setLoadingMasterTurnsForm(true));

        const response = await catalogAPI.getMasterTurnsRemaining();
        dispatch(setMasterTurnsRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterTurnsForm(false));

        dispatch(setMasterTurnsRemainingLoading(false));
    }
};

export const fetchMasterRequestStatesRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setMasterRequestStatesRemainingLoading(true));
        dispatch(setLoadingMasterRequestStatesForm(true));
        const response = await catalogAPI.getMasterRequestStatesRemaining();
        dispatch(setMasterRequestStatesRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterRequestStatesForm(false));
        dispatch(setMasterRequestStatesRemainingLoading(false));
    }
};
export const fetchdockBlocks = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setDockBlockLoading(true));
        const response = await catalogAPI.getDockBlocks();
        dispatch(setDockBlockData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setDockBlockLoading(false));
    }
};

export const fetchActuationGroupRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setActuationGroupRemainingLoading(true));
        dispatch(setLoadingMasterActuationGroupForm(true));

        const response = await catalogAPI.getActuationGroupRemaining();
        dispatch(setActuationGroupRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterActuationGroupForm(false));

        dispatch(setActuationGroupRemainingLoading(false));
    }
};

export const fetchActuationTypeRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setActuationTypesRemainingLoading(true));
        dispatch(setLoadingMasterActuationTypeForm(true));

        const response = await catalogAPI.getActuationTypeRemaining();
        dispatch(setActuationTypesRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterActuationTypeForm(false));
        dispatch(setActuationTypesRemainingLoading(false));
    }
};

export const fetchGroupsMerchandiseCategoryRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setGroupManagementCategoryRemainingLoading(true));
        dispatch(setLoadingMasterGroupMerchandiseCategoryForm(true));

        const response = await catalogAPI.getMasterGroupMerchandiseCategoryRemaining();
        dispatch(setGroupManagementCategoryRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterGroupMerchandiseCategoryForm(false));
        dispatch(setGroupManagementCategoryRemainingLoading(false));
    }
};

export const fetchCustomsStatesRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setCustomsStatesRemainingLoading(true));
        dispatch(setLoadingMasterCustomsStatesForm(true));

        const response = await catalogAPI.getMasterCustomsStatesRemaining();
        dispatch(setCustomsStatesRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterCustomsStatesForm(false));
        dispatch(setCustomsStatesRemainingLoading(false));
    }
};

export const fetchMerchandiseCategoryGroup = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setMerchandiseCategoryGroupLoading(true));

        const response = await catalogAPI.getMasterGroupMerchandiseCategoryList();
        dispatch(setMerchandiseCategoryGroupData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setMerchandiseCategoryGroupLoading(false));
    }
};

export const fetchNotificationTypesRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setNotificationTypesRemainingLoading(true));
        dispatch(setLoadingMasterNotificationTypesForm(true));
        const response = await catalogAPI.getMasterNotificationTypesRemaining();
        dispatch(setNotificationTypesRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterNotificationTypesForm(false));
        dispatch(setNotificationTypesRemainingLoading(false));
    }
};

export const fetchTransportUnitRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setTransportUnitRemainingLoading(true));
        dispatch(setLoadingMasterTransportUnitForm(true));
        const response = await catalogAPI.getMasterTransportUnitRemaining();
        dispatch(setTransportUnitRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterTransportUnitForm(false));
        dispatch(setTransportUnitRemainingLoading(false));
    }
};

export const fetchAppointmentTimeZone = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setAppointmentTimeZoneLoading(true));
        const response = await catalogAPI.getAppointmentTimeZone();
        dispatch(setAppointmentTimeZoneData(response ?? []));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setAppointmentTimeZoneLoading(false));
    }
};
