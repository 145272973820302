import { createUseStyles } from "react-jss";

export const EditRequestInspectionFormStyle = createUseStyles((theme: any) => ({
    generalContainer: {
        width: "100%",
        overflow: "auto",
    },
    spaceWithScroll: {
        paddingRight: 15,
    },
    form: {
        width: theme.widths.sectionContainer.complete,
        display: "flex",
        flex: "1 1 auto",
        gap: theme.gaps.form.default,
        justifyItems: "start",
        flexFlow: "column",
        paddingTop: 25,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        columnGap: 24,
        alignItems: "end",
        rowGap: theme.gaps.sizes.xl,
    },
    lastRow: {
        display: "flex",
        flexDirection: "row",
        columnGap: 100,
        rowGap: theme.gaps.sizes.xl,
    },
    actions: {
        width: theme.widths.sectionContainer.complete,
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        gap: theme.gaps.sizes.md,
        paddingTop: theme.paddings.siteHeader.title,
        paddingBottom: theme.paddings.default,
        "&  > div:nth-child(2)": {
            display: "flex",
            gap: theme.gaps.sizes.md,
        },
    },
    warningMenssage: {
        color: theme.colors.titles.primary.color,
        fontWeight: theme.fonts.weight.bolder,
    },
    manualContainer: {
        marginTop: -15,
    },
    yearContainer: {
        width: "48%",
    },
    timeContainer: {
        marginBottom: "-4px",
    },
    disclaimer: {
        display: "flex",
        alignItems: "center",
        columnGap: 4,
        color: theme.colors.form.input.default,
        fontSize: theme.fonts.sizes.sm,
        "& span": {
            color: theme.colors.titles.danger.color,
            fontWeight: 600,
        },
    },
}));
