import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { IContextualMenuItem } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchOrganizations, fetchRoleOptions } from "../../../redux/actions/catalog/catalog";
import { IGenericPayload, IOrganization, IRole } from "../../../models";
import { compileNameOfProperty } from "../../../utils";
import { IManagementUserForm, setShowPopUpUser, updateManagementUserProperty } from "../../../redux/reducers/management/user/form";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { UserManagementBaseForm } from "./form/baseForm";
import { ManagementUserFilter } from "./filter/filter";
import { ManagementUserList } from "./list/list";
import { ButtonContextualMenu } from "../../common/contextualMenu/contextualMenu";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
import { getRolesFiltered } from "../../../common/utils/filterUserRole";
import { IOrganizationData } from "../../../models/user/IOrganizationData";
import { RoleType } from "../../../common/enum";
import { IUserAuthData } from "../../../models/user/IUserAuthData";
import { useSelector } from "react-redux";
import { getUserData } from "../../../redux/selectors/userData/userData";

export const ManagementUserContainer = () => {
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const roles = useAppSelector((store) => store.catalog.roles.data);
    const activeRole = useAppSelector((store) => store.role.roleData);
    const activeUser: IUserAuthData = useSelector(getUserData);
    const organizations: IOrganization[] = useAppSelector((store) => store.catalog.organizations.data);
    const currentOrganization = useAppSelector((store) => store.organization.organization.id);

    useEffect(() => {
        if (roles === undefined) {
            dispatch(fetchRoleOptions());
        }
    }, [roles]);

    useEffect(() => {
        !organizations.length && dispatch(fetchOrganizations());
    }, [dispatch, organizations]);

    const getUserOrganizations = () => {
        let organizations : IOrganizationData[] = [];
        const organization: IOrganizationData = {
            id: currentOrganization
        };
        organizations.push(organization);
        return organizations;
    };

    const getAvailableOrganizationsByRole  = (role: IRole) => {
        var availableOrganizations : IOrganizationData[] = [];

        if (role.multiOrganization && activeRole.id === RoleType.SUPER_ADMIN) {
            organizations.forEach((item) => {
                const organization: IOrganizationData = {
                    id: item.id
                };
                availableOrganizations.push(organization);
            });
        } else if (role.multiOrganization && activeRole.id !== RoleType.SUPER_ADMIN) {
            activeUser.organizations.forEach((item) => {
                const organization: IOrganizationData = {
                    id: item.id
                };
                availableOrganizations.push(organization);
            });
        } else {
            const organization: IOrganizationData = {
                id: currentOrganization
            };
            availableOrganizations.push(organization);
        }

        return availableOrganizations;
    };

    const menuItems: IContextualMenuItem[] = getRolesFiltered(roles ?? [], activeRole.roleManagement).map((role) => {
        return {
            key: role.id,
            text: role.name,
            onClick: () => {
                dispatch(setShowPopUpUser(PopUpCodes.CREATE));
                let payload: IGenericPayload = {
                    name: compileNameOfProperty<IManagementUserForm>("roleId"),
                    value: role.id,
                };
                dispatch(updateManagementUserProperty(payload));
                let payloadOrganizations: IGenericPayload = {
                    name: compileNameOfProperty<IManagementUserForm>("organizations"),
                    value: getUserOrganizations(),
                };
                dispatch(updateManagementUserProperty(payloadOrganizations));
                let payloadAvailableOrganizations: IGenericPayload = {
                    name: compileNameOfProperty<IManagementUserForm>("availableOrganizations"),
                    value: getAvailableOrganizationsByRole(role),
                };
                dispatch(updateManagementUserProperty(payloadAvailableOrganizations));
            },
        } as IContextualMenuItem;
    });

    return (
        <>
            <NavHeaderComponent
                title={t("MANAGEMENT.USER.TITLE")}
                action={
                    <ButtonContextualMenu
                        items={menuItems}
                        buttonTitle={t("MANAGEMENT.USER.BUTTON.ADD")}
                    />
                }
            />

            <UserManagementBaseForm />
            <ManagementUserFilter />
            <ManagementUserList />
        </>
    );
};
