import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import React, { useEffect } from "react";
import { fetchOrganisms } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapAllOptionIDescriptionToDropDownOptions, mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors/controller/controlledSelector";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

export const OrganismSelector: React.FC<IBaseSelectorProps> = ({
    onChange,
    selectedKey,
    allOptionText,
    propertyName,
    title,
    isRequired,
    control,
    rules,
    disabled,
    allOption,
    calloutLabel,
    calloutMessage,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.organisms);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data.length === 0 && dispatch(fetchOrganisms());
    }, [dispatch, data]);

    const onSelectorChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChange(option, propertyName);
    };

    return (
        <ControlledDropdown
            required={isRequired}
            options={allOption ? mapAllOptionIDescriptionToDropDownOptions(data, allOptionText) : mapIDescriptionToDropDownOptions(data)}
            label={title}
            control={control}
            name={propertyName}
            rules={rules}
            disabled={disabled ?? loading}
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            calloutMessage={calloutMessage}
            calloutLabel={calloutLabel}
        />
    );
};
