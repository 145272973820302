import { RequestApi } from "../../../../api/request";
import { routeFeatures } from "../../../../common/constants/routeFeatures";
import { Milliseconds, RoleType } from "../../../../common/enum";
import { DefaultValues } from "../../../../common/enum/DefaultValues";
import { OrderColumn } from "../../../../common/enum/OrderColumn";
import { HttpStatus } from "../../../../common/enum/api/HttpStatus";
import { handleError } from "../../../../common/utils/handleError";
import { Toastr } from "../../../../components/common/toast/toast";
import { defaultErrorOptions } from "../../../../components/common/toast/toastOptions";

import { FullRequestFilter, IRequestDetailWithIndex, IRequestFilter } from "../../../../models";

import messages from "../../../../translations/es/messages.json";
import {
    compileNameOfProperty,
    removeDefaultOptionsFromNumberArray,
    removeDefaultOptionsFromStringArray,
    throwErrorToastrAsync,
} from "../../../../utils";
import { updateImpersonateOrganismIdFilter } from "../../../reducers/request/list/filter";
import { fetchRequestList, loadingRequestList, setPage, updateSelectedChangeDate, updateStateId } from "../../../reducers/request/list/requestlist";
import { setRole } from "../../../reducers/user/role/role";
import { setImpersonatedOrganismId } from "../../../reducers/userData/userData";
import { AppThunk, store } from "../../../store";
import { fetchViewsToIdRole } from "../../catalog/catalog";
import { setGlobalLoadingAction } from "../../common";
import { fetchViewsAction } from "../../user/role/view";
const requestApi = new RequestApi();

export const capitalizeAllFirstLetter = (value: string | string[]) => {
    return Array.isArray(value)
        ? value.map((item: string) => {
              return capitalizeFirstLetter(item);
          })
        : capitalizeFirstLetter(value);
};

export const fetchRequestListAction =
    (page: number = 1): AppThunk =>
    async (dispatch) => {
        try {
            const { sort, pagination } = store.getState().requestlist;
            const { startDate, endDate, filter } = store.getState().requestListFilter;
            const { id } = store.getState().role.roleData;

            const fullFilter = completeRequestFilter({ ...filter }, startDate, endDate);

            dispatch(loadingRequestList(true));

            let customOrderBy = Array.isArray(sort.column)
                ? sort.column.map((item: string, index: number) => {
                      return {
                          field: capitalizeAllFirstLetter(item),
                          order: index === 0 ? sort.orderColumn : OrderColumn.ASC,
                      };
                  })
                : [];

            const response = await requestApi.getRequestsAsync(pagination.pageSize, page, customOrderBy, fullFilter, id);
            dispatch(fetchRequestList(response));
            dispatch(setPage(page));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(loadingRequestList(false));
        }
    };

export const fetchSilentRequestListAction = (): AppThunk => async (dispatch) => {
    const idToastr = fetchSilentRequestListAction.name;
    try {
        const { sort, pagination, loading } = store.getState().requestlist;
        const { startDate, endDate, filter } = store.getState().requestListFilter;
        const { id } = store.getState().role.roleData;

        const fullFilter = completeRequestFilter({ ...filter }, startDate, endDate);
        if (loading) return;
        dispatch(loadingRequestList(true));
        let customOrderBy = Array.isArray(sort.column)
            ? sort.column.map((item: string, index: number) => {
                  return {
                      field: capitalizeAllFirstLetter(item),
                      order: index === 0 ? sort.orderColumn : OrderColumn.ASC,
                  };
              })
            : [];
        const response = await requestApi.getRequestsAsync(pagination.pageSize, pagination.currentPage, customOrderBy, fullFilter, id);
        dispatch(fetchRequestList(response));
        dispatch(loadingRequestList(false));
    } catch (error: any) {
        if (error.response && error.response.status === HttpStatus.UNAUTHORIZED) {
            window.location.reload();
        } else {
            Toastr(messages.API.ERROR, defaultErrorOptions(), error).update(idToastr);
        }
        dispatch(loadingRequestList(false));
    }
};

export const patchRequestsDate =
    (requestIds: number[], date: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            const result = await requestApi.patchRequestsDateAsync(requestIds, date);
            if (result) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                dispatch(updateSelectedChangeDate(undefined));
                dispatch(fetchRequestListAction());
            }
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setGlobalLoadingAction(false));
        }
    };

export const patchRequestState =
    (requestId: number, stateId: number, index: number, currentStateId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            const result = await requestApi.patchRequestState(requestId, stateId, currentStateId);
            if (result) {
                const payload: IRequestDetailWithIndex = {
                    index,
                    stateId,
                };
                dispatch(updateStateId(payload));
            }
        } catch (error: Error | any) {
            handleError(error, dispatch);
        } finally {
            dispatch(setGlobalLoadingAction(false));
        }
    };

export const patchRequestInspectionsAssignInspector = (requestId: number, inspectionId: number, inspectorId: string): any => {
    return async (dispatch: any) => {
        try {
            await requestApi.patchRequestInspectionsAssignInspector(requestId, inspectionId, inspectorId);
            process.env.REACT_APP_DEVELOPMENT && (await new Promise((r) => setTimeout(r, Milliseconds.TWO_SEC)));
            dispatch(fetchSilentRequestListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        }
    };
};

export const patchRequestInspectionsUnassignInspector = (requestId: number, inspectionId: number): any => {
    return async (dispatch: any) => {
        try {
            await requestApi.patchRequestInspectionsUnassignInspector(requestId, inspectionId);
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            process.env.REACT_APP_DEVELOPMENT && (await new Promise((r) => setTimeout(r, Milliseconds.TWO_SEC)));
            dispatch(fetchSilentRequestListAction());
        }
    };
};

export const suplantOrganismUser =
    (currentPage?: number, impersonatedOrganismId?: string, showUpdateRequest?: boolean, back?: () => any): AppThunk =>
    async (dispatch) => {
        const { data } = store.getState().catalog.organisms;
        const { roleData } = store.getState().role;
        const { principalRole } = store.getState().userData.profile;
        const { roles, organismId: realOrganismId } = store.getState().userData.profile;
        const isOrganismUser =
            roleData.id === RoleType.ORGANISM_ADMIN || roleData.id === RoleType.ORGANISM_TECHNICIAN || roleData.id === RoleType.ORGANISM_INSPECTOR;
            

        if (!impersonatedOrganismId && isOrganismUser) {
            dispatch(fetchRequestListAction(currentPage));
            return;
        }

        const setImpersonatedRole = (roleId: RoleType, organismId?: number) => {
            dispatch(setImpersonatedOrganismId(organismId ?? undefined));
            dispatch(setRole({ ...roleData, id: roleId }));
            if (organismId) {
                dispatch(
                    updateImpersonateOrganismIdFilter({
                        name: compileNameOfProperty<IRequestFilter>("organismId"),
                        value: [organismId],
                    }),
                );
            } else {
                dispatch(
                    updateImpersonateOrganismIdFilter({
                        name: compileNameOfProperty<IRequestFilter>("organismId"),
                        value: [-1],
                    }),
                );
            }
        };

        if (impersonatedOrganismId) {
            const organism = data.find((item) => item.code === impersonatedOrganismId);
            const organismId = organism?.id || undefined;

            setImpersonatedRole(RoleType.ORGANISM_ADMIN, organismId);

            if (realOrganismId !== organismId) {
                dispatch(fetchViewsToIdRole(routeFeatures.REQUEST, RoleType.ORGANISM_ADMIN));
            }
        } else {
            if (roles) {
                if (showUpdateRequest) {
                    dispatch(fetchViewsAction(true));
                } else if (back) {
                    back();
                }
            }
            setImpersonatedRole(principalRole as RoleType);
        }

        if (currentPage) {
            dispatch(fetchRequestListAction(currentPage));
        }
    };

export const capitalizeFirstLetter = (columnName: string) => {
    return columnName.charAt(0).toUpperCase() + columnName.slice(1);
};

export const completeRequestFilter = (requestFilter: IRequestFilter, startDate: string, endDate?: string): FullRequestFilter => {
    let data = store.getState().catalog.valuedTypes.data;
    return {
        startDate,
        endDate,
        app: requestFilter.app,
        vua: requestFilter.vua,
        parkingAuthorization: requestFilter.parkingAuthorization,
        appointmentAssigned: requestFilter.appointmentAssigned,
        halalCertified: requestFilter.halalCertified,
        dryCharge: requestFilter.dryCharge,
        fumigated: requestFilter.fumigated,
        organicProduct: requestFilter.organicProduct,
        summaryActive: requestFilter.summaryActive,
        sort: requestFilter.sort,
        certificateNumber: requestFilter.certificateNumber,
        summaryNumber: requestFilter.summaryNumber,
        invoiced: requestFilter.invoiced,
        transportUnitNumber: requestFilter.transportUnitNumber,
        description: requestFilter.description,
        transportUnitId: removeDefaultOptionsFromNumberArray(requestFilter.transportUnitId),
        transportUnitSizeId: removeDefaultOptionsFromNumberArray(requestFilter.transportUnitSizeId),
        stateId: removeDefaultOptionsFromNumberArray(requestFilter.stateId),
        dockId: removeDefaultOptionsFromNumberArray(requestFilter.dockId),
        turnId: removeDefaultOptionsFromNumberArray(requestFilter.turnId),
        customerId: removeDefaultOptionsFromStringArray(requestFilter.customerId),
        terminalId: removeDefaultOptionsFromNumberArray(requestFilter.terminalId),
        merchandiseTypeId: removeDefaultOptionsFromNumberArray(requestFilter.merchandiseTypeId),
        merchandiseOriginId: removeDefaultOptionsFromNumberArray(requestFilter.merchandiseOriginId),
        customsStateId: removeDefaultOptionsFromNumberArray(requestFilter.customsStateId),
        finalDestinationTypeId: removeDefaultOptionsFromNumberArray(requestFilter.finalDestinationTypeId),
        inspectionFilter: {
            organismId: removeDefaultOptionsFromNumberArray(requestFilter.organismId),
            actuationTypeGroupId: removeDefaultOptionsFromNumberArray(requestFilter.actuationTypeGroupId),
            actuationTypeId: removeDefaultOptionsFromNumberArray(requestFilter.actuationTypeId),
            certificateTypeId: removeDefaultOptionsFromNumberArray(requestFilter.certificateTypeId),
            inspectorId: removeDefaultOptionsFromStringArray(requestFilter.inspectorId),
            inspectorAssigned: requestFilter.inspectorAssigned,
        },
        documentTypeId: removeDefaultOptionsFromNumberArray(requestFilter.documentTypeId),
        valued: removeDefaultOptionsFromStringArray(
            requestFilter.valued.map((item: string) => {
                if (item === DefaultValues.DEFAULT_ALL_SELECTOR) {
                    return item;
                }

                let translateCode = data?.find((value: any) => {
                    return value.code === item;
                });

                return translateCode?.description ?? "";
            }),
        ),
        appointmentTimeZoneId: removeDefaultOptionsFromNumberArray(requestFilter.appointmentTimeZoneId),
    };
};
