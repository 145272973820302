import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IGenericPayload } from "../../../../models";
import {
    createOrUpdateManagementUserAction,
    migrateManagementUserAction,
    setManagementUserRegisteredAction,
    setManagementUserUnregisteredAction,
} from "../../../../redux/actions/management/user/user";
import { useAppSelector } from "../../../../redux/hooks";
import {
    resetManagementUser,
    setManagementUser,
    setShowPopUpUser,
    setWarningExternalCodePopUp,
    updateManagementUserProperty,
} from "../../../../redux/reducers/management/user/form";
import { getGenericPayloadFromEvent } from "../../../../utils";

import { ManagementUserDefaultForm } from "./default/defaultForm";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";
import { widths } from "../../../../styles/romeu/widths";
import { RegisteredAndUnregisteredActions } from "../../../common/formUtils/registeredAndUnregistered";
import { ManagementUserCustomerAdminForm } from "./adminCustomer/adminCustomerForm";
import { ManagementUserCarrierUserForm } from "./adminCarrier/adminCarrier";
import { resetUsersByRole } from "../../../../redux/reducers/user/users/usersByRole";
import { ManagementUserOrganismInspectorForm } from "./organismInspector/organismInspectorForm";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { ManagementUserTerminalAdminForm } from "./terminalAdminForm/terminalAdminForm";
import { ManagementUserOrganismAdminForm } from "./organismAdminForm/organismAdminForm";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { RoleType } from "../../../../common/enum";
import { ButtonPrimaryInverse } from "../../../common/buttons/buttons";

export const UserManagementBaseForm = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.USER" });
    const dispatch = useDispatch();
    const { generalUserData, loading, showPopUp, warningExternalCodePopUp } = useAppSelector((store) => store.managementUserForm);
    const [isAvailableSave, setIsAvailableSave] = useState<boolean>(false);
    const [externalCode, setExternalCode] = useState<string>("");
    const [userMigration, setUserMigration] = useState<boolean>(false);

    const [showMigrationAlertRequest, setShowMigrationAlertRequest] = useState(false);

    const isVisiblePopUp = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE;
    }, [showPopUp]);

    const titlePopUp = useMemo(() => {
        if (userMigration) {
            return t("BUTTON.MIGRATION");
        } else if (showPopUp === PopUpCodes.CREATE) {
            return t("BUTTON.ADD");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("BUTTON.EDIT");
        }

        return "";
    }, [showPopUp, userMigration]);

    const showMigrationAction = useMemo(() => {
        if (userMigration) {
            return false;
        }
        return generalUserData.roleId === RoleType.CUSTOMER_ADMIN || generalUserData.roleId === RoleType.ORGANISM_ADMIN;
    }, [generalUserData.roleId, userMigration]);

    const onDimissed = () => {
        dispatch(resetManagementUser());
    };
    const onCloseForm = () => {
        dispatch(setShowPopUpUser(PopUpCodes.NONE));
        setIsAvailableSave(false);
        setExternalCode("");
        setUserMigration(false);
        onCancelMigrationRequest();
    };

    const onSaveForm = () => {
        if (generalUserData.id && externalCode && externalCode !== generalUserData.externalCode && warningExternalCodePopUp === "") {
            dispatch(setWarningExternalCodePopUp(PopUpCodes.WARNING));
        } else {
            warningExternalCodePopUp !== "" && dispatch(setWarningExternalCodePopUp(""));

            if (userMigration) {
                dispatch(migrateManagementUserAction(generalUserData)).then((response: any) => {
                    if (response && generalUserData.id) {
                        dispatch(resetUsersByRole());
                        onCloseForm();
                    }
                });
            } else {
                dispatch(createOrUpdateManagementUserAction(generalUserData)).then((response: any) => {
                    if (response) {
                        dispatch(resetUsersByRole());
                        onCloseForm();
                    }
                });
            }
        }
    };

    const onUnregisterUser = () => {
        if (generalUserData.id) {
            dispatch(setManagementUserUnregisteredAction(generalUserData.id));
        }
    };

    const onRegisterUser = () => {
        if (generalUserData.id) {
            dispatch(setManagementUserRegisteredAction(generalUserData.id));
        }
    };

    const updatePropertyForm = (name: any, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };
        dispatch(updateManagementUserProperty(payload));
    };

    const onSelectorChange = (event: any, option: any, name: string) => {
        updatePropertyForm(name, option.key);
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            updatePropertyForm(name, value);
        },
        [dispatch],
    );

    const formUser = () => {
        switch (generalUserData.roleId) {
            case RoleType.ORGANISM_ADMIN:
                return (
                    <ManagementUserOrganismAdminForm
                        onSelectorChange={onSelectorChange}
                        onInputChange={onInputChange}
                        editMode={!userMigration ? showPopUp === PopUpCodes.UPDATE : false}
                        setIsAvailableSave={setIsAvailableSave}
                    />
                );
            case RoleType.CUSTOMER_ADMIN:
                return (
                    <ManagementUserCustomerAdminForm
                        onInputChange={onInputChange}
                        setIsAvailableSave={setIsAvailableSave}
                        userMigration={userMigration}
                    />
                );
            case RoleType.CARRIER_ADMIN:
                return (
                    <ManagementUserCarrierUserForm
                        onInputChange={onInputChange}
                        setIsAvailableSave={setIsAvailableSave}
                    />
                );

            case RoleType.ORGANISM_INSPECTOR:
                return (
                    <ManagementUserOrganismInspectorForm
                        onSelectorChange={onSelectorChange}
                        onInputChange={onInputChange}
                        editMode={showPopUp === PopUpCodes.UPDATE}
                        setIsAvailableSave={setIsAvailableSave}
                    />
                );
            case RoleType.TERMINAL_ADMIN:
                return (
                    <ManagementUserTerminalAdminForm
                        onSelectorChange={onSelectorChange}
                        onInputChange={onInputChange}
                        editMode={showPopUp === PopUpCodes.UPDATE}
                        setIsAvailableSave={setIsAvailableSave}
                    />
                );
            default:
                return (
                    <ManagementUserDefaultForm
                        onSelectorChange={onSelectorChange}
                        onInputChange={onInputChange}
                        editMode={showPopUp === PopUpCodes.UPDATE}
                        setIsAvailableSave={setIsAvailableSave}
                    />
                );
        }
    };

    const heigthPopUp = () => {
        switch (generalUserData.roleId) {
            case RoleType.ORGANISM_ADMIN:
                return "39vh";
            case RoleType.CUSTOMER_ADMIN:
                return "72vh";
            case RoleType.CARRIER_ADMIN:
                return "50vh";
            default:
                return "32vh";
        }
    };

    const onCancelMigrationRequest = () => {
        setShowMigrationAlertRequest(false);
    };

    const onAcceptMigrationRequest = () => {
        onCancelMigrationRequest();
        setUserMigration(true);
        migrationCopyData();
    };

    const migrationCopyData = async () => {
        let customForm: any;
        switch (generalUserData.roleId) {
            case RoleType.ORGANISM_ADMIN:
                customForm = {
                    ...generalUserData,
                    name: "",
                    email: "",
                    phoneNumber: "",
                };

                break;
            case RoleType.CUSTOMER_ADMIN:
                customForm = {
                    ...generalUserData,
                    name: "",
                    surName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                };

                break;

            default:
                break;
        }

        dispatch(setManagementUser(customForm));
    };

    useEffect(() => {
        externalCode === "" && setExternalCode(generalUserData.externalCode ?? "");
    }, [generalUserData]);

    return (
        <>
            <PopUpForm
                content={formUser()}
                leftButtonContent={
                    showPopUp === PopUpCodes.UPDATE ? (
                        <>
                            {showMigrationAction && (
                                <>
                                    <ButtonPrimaryInverse
                                        title={t("BUTTON.MIGRATION")}
                                        handleClick={() => {
                                            setShowMigrationAlertRequest(true);
                                        }}
                                    />

                                    <PopUpConfirmation
                                        showPopUp={showMigrationAlertRequest}
                                        onCancel={onCancelMigrationRequest}
                                        onAccept={onAcceptMigrationRequest}
                                        type={PopUpConfirmationType.WARNING}
                                        width={"30vw"}
                                        height={"auto"}
                                        message={t("BUTTON.INFO_MIGRATION")}
                                    />
                                </>
                            )}

                            <RegisteredAndUnregisteredActions
                                onRegisteredUser={onRegisterUser}
                                onUnregisteredUser={onUnregisterUser}
                                visibleRequiredMessage={true}
                                hiddenAction={userMigration}
                                disabled={loading}
                            />
                        </>
                    ) : (
                        <RequiredFieldsMessage />
                    )
                }
                onDimissed={onDimissed}
                width={widths.management.user.userForm.width}
                height={heigthPopUp()}
                title={titlePopUp}
                isDisabledButton={!isAvailableSave}
                isLoading={loading}
                isVisible={isVisiblePopUp}
                onSave={onSaveForm}
                onCancel={onCloseForm}
                buttonSaveTitle={userMigration ? t("BUTTON.MIGRATION") : undefined}
            />

            <PopUpConfirmation
                showPopUp={warningExternalCodePopUp === PopUpCodes.WARNING}
                onCancel={() => {
                    dispatch(setWarningExternalCodePopUp(""));
                }}
                message={t("EXTERNAL_CODE_WARNING")}
                height={"auto"}
                onAccept={onSaveForm}
                type={PopUpConfirmationType.INFO}
                width={"50vw"}
                loading={loading}
            />
        </>
    );
};
