import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IUserOrganization } from "../../../../models/user/IUserOrganization";

export const organizationAdapter = createEntityAdapter<IUserOrganization>({});

export const organizationSlice = createSlice({
    name: "organization",
    initialState: organizationAdapter.getInitialState({
        isLoading: false,
        hasErrors: false,
        errorMessage: "",
        organization: {
            id: "",
            name: "",
        },
        organizationData: {
            id: 0,
            name: "",
            fiscalName: "",
            fromEmail: "",
            email: "",
            contactEmail: "",
            address: "",
            postalCode: "",
            city: "",
            fiscalAddress: "",
            fumigated: false,
            dryCharge: false,
            organicProduct: false,
            halalCertified: false,
            preappointment: false,
            parkingAuthorization: false,
            apba: false,
            parkingBarrier: false,
            summaryActive: false,
            valenciaport: false,
            vua: false,
            events: {
                customsAuthorityArrival: {
                    active: true,
                    requiredForInspection: true,
                },
                representativeArrival: {
                    active: true,
                    requiredForInspection: false,
                },
                readyForInstruction: {
                    active: false,
                    requiredForInspection: false,
                },
            },
        },
    }),
    reducers: {
        setOrganization: (state, action) => {
            state.organization = action.payload;
        },
        setOrganizationLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setOrganizationData: (state, action: PayloadAction<any>) => {
            state.organizationData = action.payload;
        },
        updateOrganizationProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.organizationData = {
                ...state.organizationData,
                [action.payload.name]: action.payload.value,
            };
        },
        updateOrganizationEventProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.organizationData.events = {
                ...state.organizationData.events,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { setOrganization, setOrganizationData, updateOrganizationProperty, setOrganizationLoading, updateOrganizationEventProperty } =
    organizationSlice.actions;

export default organizationSlice.reducer;
