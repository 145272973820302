import { IActuation, IEditRequest, IInspection } from "../../models/requests/IEditRequest";
import { IRequestDocument } from "../../models/requests/IRequestDocument";
import { FullRequestFilter, ICreateRequest, IDocument, IResumeCreateRequest, ITransportUnitData } from "../../models";
import { IRequestList } from "../../models/requests/IRequestList";
import { IRequestOrderBy } from "../../models/requests/IRequestSearch";
import { apiFetchOAuthWithClaims } from "../../services/apiClient";
import { compileNameOfProperty } from "../../utils";
import { ICreateIncidence, IRequestIncidenceItem } from "../../models/requests/IRequestIncidence";
import { IBillingdata, IConceptFormTariff } from "../../models/requests/IEditRequestBilling";
import { convertToLocal } from "../../utils/dateUtils";
import { FormatDate } from "../../common/enum/dateTime/FormatDate";
import { IRequestStateHistory } from "../../models/requests/IRequestStateHistory";
import { Method } from "../../common/enum";
import { IAvailableDates } from "../../models/requests/utils/IAvailableDates";
import { IRequestPendingAssignment } from "../../models/assignment/IAssignment";
import { IRequestHistory } from "../../models/requests/request/IRequestHistory";
import { FinishedRequest } from "../../models/vlcPort/IFinishedRequest";
import { IGenericImportFinishedData } from "../../models/common/IImportGeneric";
import { IVlcportHistoricList } from "../../models/vlcPort/IHistoricRequest";
import { IAppointmentData } from "../../models/appointment/IAppointmentAssign";
import { IAddInspectionData } from "../../redux/reducers/request/list/form/addInspection";
import { IAssignmentQueueImportForm } from "../../redux/reducers/request/assignmentQueue/form";
import { getCustomerReference } from "../../services/user/customerService";
import { store } from "../../redux/store";

const baseURL = `${process.env.REACT_APP_API_URL}/requests`;

export class RequestApi {
    async getRequestsAsync(size: number, page: number, ordersBy: IRequestOrderBy[], filter: FullRequestFilter, impersonatedRoleId?: string): Promise<IRequestList> {
        const url = `${baseURL}/search`;
        const roleId = impersonatedRoleId ?? null;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody({ size, page, ordersBy, filter, impersonatedRoleId: roleId  }).execute<IRequestList>();
    }

    async patchRequestsDateAsync(requestIds: number[], date: string): Promise<boolean> {
        const url = `${baseURL}/date/${date}`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).withBody(requestIds).execute<boolean>();
    }

    async getExportRequestsAsync(ordersBy: IRequestOrderBy | IRequestOrderBy[], filter: FullRequestFilter, impersonatedRoleId?: string): Promise<string> {
        const url = `${baseURL}/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody({ ordersBy, filter, impersonatedRoleId }).execute<any>();
    }

    async getEditRequest(idRequest: string): Promise<IEditRequest> {
        const url = `${baseURL}/${idRequest}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IEditRequest>().then(mapToEditRequest);
    }

    async deleteRequest(idRequest: number): Promise<IActuation> {
        const url = `${baseURL}/${idRequest}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).execute<IActuation>();
    }

    async createRequestAsync(requestBody: ICreateRequest): Promise<IResumeCreateRequest[]> {
        const options = store.getState().usersByRole.invoiceCustomers.options;
        const representative = getCustomerReference(+requestBody.invoiceCustomerId, options);
        const requestCopy: ICreateRequest = {
            ...requestBody,
            invoiceCustomerId: requestBody.invoiceCustomerId.toString(),
            transportUnits: mapToAPITransportUnits(requestBody.transportUnits),
            representativeId: representative ?? null,
            date: new Date(convertToLocal(requestBody.date, FormatDate.ISO)),
        };

        return apiFetchOAuthWithClaims(Method.POST, baseURL).withBody(requestCopy).execute<IResumeCreateRequest[]>();
    }

    async getRequestDocuments(idRequest: number): Promise<IRequestDocument[]> {
        const url = `${baseURL}/${idRequest}/documents`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IRequestDocument[]>();
    }

    async getTypeDocument(): Promise<any> {
        const url = `${baseURL}/documents`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute();
    }

    async deleteDocumentRequest(requestId: number, documentId: number): Promise<any> {
        const url = `${baseURL}/${requestId}/documents/${documentId}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).execute<boolean>();
    }

    async uploadDocuments(documentTypeId: string, document: File, requestId: number): Promise<any> {
        const url = `${baseURL}/${requestId}/documents`;
        const formData = new FormData();
        formData.append(compileNameOfProperty("documentTypeId"), documentTypeId);
        formData.append(compileNameOfProperty("document"), document);
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(formData).execute<boolean>();
    }

    async getPhotos(idRequest: number): Promise<any> {
        const url = `${baseURL}/${idRequest}/photos`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<boolean>();
    }

    async deleteImageRequest(requestId: number, imageId: number): Promise<any> {
        const url = `${baseURL}/${requestId}/photos/${imageId}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).execute<boolean>();
    }

    async uploadImageRequest(imageRequestList: File[], requestId: number): Promise<boolean> {
        const formData = new FormData();
        const url = `${baseURL}/${requestId}/photos`;

        imageRequestList.forEach((photo: File) => {
            formData.append("photos", photo);
        });

        return apiFetchOAuthWithClaims(Method.POST, url).withBody(formData).withMultiPartHeaders().execute<boolean>();
    }

    async patchRequestState(requestId: number, stateId: number, currentStateId: number): Promise<boolean> {
        const url = `${baseURL}/${requestId}/state/${stateId}?currentStateId=${currentStateId}`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).execute<boolean>();
    }

    async getRequestStateHistory(requestId: number): Promise<IRequestStateHistory[]> {
        const url = `${baseURL}/${requestId}/statehistory`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IRequestStateHistory[]>().then(mapToRequestStateHistory);
    }

    async patchRequestStateHistory(requestId: string, stateHistory: IRequestStateHistory[]): Promise<boolean> {
        let patchStateHistory: any[] = [];

        stateHistory.forEach((state: IRequestStateHistory) => {
            patchStateHistory.push({ id: state.id, date: state.date });
        });

        const url = `${baseURL}/${requestId}/statehistory`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).withBody(patchStateHistory).execute<boolean>();
    }

    async getIncidenceRequest(requestId: number, id: number): Promise<IRequestIncidenceItem> {
        const url = `${baseURL}/${requestId}/incidences/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withMultiPartHeaders().execute<IRequestIncidenceItem>();
    }
    async getIncidencesRequests(requestId: number, category: string | undefined): Promise<boolean> {
        const url = `${baseURL}/${requestId}/incidences?category=${category}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<boolean>();
    }

    async deleteIncidencesRequests(requestId: number, incidencesId: number): Promise<boolean> {
        const url = `${baseURL}/${requestId}/incidences/${incidencesId}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).execute<boolean>();
    }

    async createIncidenceRequest(requestId: number, incidence: ICreateIncidence): Promise<ICreateIncidence> {
        const url = `${baseURL}/${requestId}/incidences`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(incidence).execute<ICreateIncidence>();
    }

    async updateIncidenceRequest(requestId: number, incidence: ICreateIncidence): Promise<ICreateIncidence> {
        const url = `${baseURL}/${requestId}/incidences/${incidence.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(incidence).execute<ICreateIncidence>();
    }

    async getRequestInvoiceConcepts(requestId: number): Promise<IBillingdata[]> {
        const url = `${baseURL}/${requestId}/invoiceconcepts`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IBillingdata[]>();
    }

    async postRequestInvoiceConcepts(requestId: number, invoicedConcept: IConceptFormTariff): Promise<boolean> {
        const url = `${baseURL}/${requestId}/invoiceconcepts`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(invoicedConcept).execute<boolean>();
    }

    async putRequestInvoiceConcepts(requestId: number, invoicedConcept: IConceptFormTariff): Promise<boolean> {
        const url = `${baseURL}/${requestId}/invoiceconcepts/${invoicedConcept.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(invoicedConcept).execute<boolean>();
    }

    async deleteRequestInvoiceConcepts(requestId: number, invoicedConceptId: number): Promise<boolean> {
        const url = `${baseURL}/${requestId}/invoiceconcepts/${invoicedConceptId}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).execute<boolean>();
    }
    async putSynchronizedRequestInvoiceConcepts(requestId: number, invoicedConceptId: number): Promise<boolean> {
        const url = `${baseURL}/${requestId}/invoiceconcepts/${invoicedConceptId}/reprocess`;
        return apiFetchOAuthWithClaims(Method.PUT, url).execute<boolean>();
    }
    async getRequestInvoiceConceptData(requestId: number, invoicedConceptId: number): Promise<IConceptFormTariff> {
        const url = `${baseURL}/${requestId}/invoiceconcepts/${invoicedConceptId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IConceptFormTariff>();
    }

    async putRequestData(request: IEditRequest): Promise<boolean> {
        const requestCopy: IEditRequest = {
            ...request,
            invoiceCustomerId: request.invoiceCustomerId.toString(),
            date: new Date(convertToLocal(request.date, FormatDate.ISO)),
            grossWeight: request.grossWeight === null ? null : +request.grossWeight,
            packages: request.packages === null ? null : +request.packages,
        };
        const url = `${baseURL}/${request.id}`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).withBody(requestCopy).execute<boolean>();
    }

    async getInspectionsRequests(requestId: number): Promise<IInspection[]> {
        const url = `${baseURL}/${requestId}/inspections`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IInspection[]>().then(mapToInspections);
    }

    async deleteInspectionRequests(requestId: number, inspectionId: number): Promise<boolean> {
        const url = `${baseURL}/${requestId}/inspections/${inspectionId}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).execute<boolean>();
    }
    async getInspectionRequestForm(requestId: number, inspectionId: number): Promise<IInspection> {
        const url = `${baseURL}/${requestId}/inspections/${inspectionId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IInspection>();
    }

    async createInspectionRequest(requestId: number, inspection: IInspection): Promise<IInspection> {
        const url = `${baseURL}/${requestId}/inspections`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(inspection).execute<IInspection>();
    }

    async updateInspectionRequest(requestId: number, inspection: IInspection): Promise<IInspection> {
        const url = `${baseURL}/${requestId}/inspections/${inspection.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(inspection).execute<IInspection>();
    }

    async postRequestInspectionActuation(requestId: number, inspectionId: number, actuation: IActuation): Promise<IActuation> {
        const url = `${baseURL}/${requestId}/inspections/${inspectionId}/actuations`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(actuation).execute<IActuation>();
    }

    async postRequestListInspectionActuationMultiple(requestIds: number[], params: IAddInspectionData): Promise<boolean> {
        const url = `${baseURL}/actuations/multiple`;
        const body = {
            ...params,
            requestIds,
        };
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(body).execute<boolean>();
    }

    async postSetMultipleActuations(requestId: number, actuations: any): Promise<IActuation> {
        const url = `${baseURL}/${requestId}/actuations/multiple`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(actuations).execute<IActuation>();
    }

    async putRequestInspectionActuation(requestId: number, inspectionId: number, actuation: IActuation): Promise<IActuation> {
        const url = `${baseURL}/${requestId}/inspections/${inspectionId}/actuations/${actuation.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(actuation).execute<IActuation>();
    }

    async deleteRequestInspectionActuation(requestId: number, inspectionId: number, actuationId: number): Promise<IActuation> {
        const url = `${baseURL}/${requestId}/inspections/${inspectionId}/actuations/${actuationId}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).execute<IActuation>();
    }
    async getRequestInspectionActuation(requestId: number, inspectionId: number, actuationId: any): Promise<IActuation> {
        const url = `${baseURL}/${requestId}/inspections/${inspectionId}/actuations/${actuationId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IActuation>();
    }

    async getExpedientActuationList(requestId: number, inspectionId: number): Promise<IActuation[]> {
        const url = `${baseURL}/${requestId}/inspections/${inspectionId}/actuations`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IActuation[]>();
    }

    async getRequestReport(requestId: number): Promise<string> {
        const url = `${baseURL}/${requestId}/report`;
        return apiFetchOAuthWithClaims(Method.GET, url).withMultiPartHeaders().execute<string>();
    }
    async patchRequestInspectionsAssignInspector(requestId: number, inspectionId: number, inspectorId: string): Promise<string> {
        const url = `${baseURL}/${requestId}/inspections/${inspectionId}/inspector/${inspectorId}`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).withMultiPartHeaders().execute<string>();
    }
    async patchRequestInspectionsUnassignInspector(requestId: number, inspectionId: number): Promise<string> {
        const url = `${baseURL}/${requestId}/inspections/${inspectionId}/inspector/unassign`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).withMultiPartHeaders().execute<string>();
    }
    async getRequestAvailableDates(params?: {
        merchandiseCategoryId: string;
        transportUnitId: string;
        transportUnitSizeId: string;
        hasAppointment: boolean;
    }): Promise<IAvailableDates> {
        const urlParams = new URLSearchParams();

        if (params) {
            if (params.merchandiseCategoryId) urlParams.append("merchandiseCategoryId", params.merchandiseCategoryId);
            if (params.transportUnitId) urlParams.append("transportUnitId", params.transportUnitId);
            if (params.transportUnitSizeId) urlParams.append("transportUnitSizeId", params.transportUnitSizeId);
            if (typeof params.hasAppointment !== "undefined") urlParams.append("hasAppointment", String(params.hasAppointment));
        }

        const url = `${baseURL}/availabledates?${urlParams.toString()}`;

        return apiFetchOAuthWithClaims(Method.GET, url).execute<IAvailableDates>();
    }

    async getRequestPendingAssignments(filter: any): Promise<IRequestPendingAssignment> {
        const url = `${baseURL}/assignmentqueue`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filter).execute<IRequestPendingAssignment>();
    }

    async getRequestTimeLineHistory(requestId: string): Promise<IRequestHistory[]> {
        const url = `${baseURL}/${requestId}/history`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IRequestHistory[]>();
    }
    async getRequestHistoryChange(requestId: number, id: string, previousId: string): Promise<any> {
        const flag = previousId !== "" ? `?previousId=${previousId}` : "";

        const url = `${baseURL}/${requestId}/history/${id}${flag}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<any>();
    }

    async patchCustomerNotified(requestId: string): Promise<IRequestHistory[]> {
        const url = `${baseURL}/${requestId}/customernotified`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).execute<IRequestHistory[]>();
    }

    async fetchVlcPortNotFinishedRequest(): Promise<FinishedRequest[]> {
        const url = `${baseURL}/valenciaport/notfinished`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<FinishedRequest[]>();
    }

    async importRequestVlcPort(excelFile: any, xml: string): Promise<IGenericImportFinishedData> {
        const flag = excelFile ? "/import" : "";
        const url = `${baseURL}/valenciaport${flag}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapImportRequestVlcPortForm(excelFile, xml)).execute<IGenericImportFinishedData>();
    }
    async importRequestAssignmentQueue(form: any): Promise<IGenericImportFinishedData> {
        const url = `${baseURL}/import`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapImportAssignmentQueueForm(form, "json")).execute<IGenericImportFinishedData>();
    }

    async importRequestAssignmentQueueDocument(form: any): Promise<IGenericImportFinishedData> {
        const url = `${baseURL}/import/document`;
        return apiFetchOAuthWithClaims(Method.POST, url)
            .withBody(mapImportAssignmentQueueForm(form, "document"))
            .execute<IGenericImportFinishedData>();
    }

    async importRequestAssignmentQueueText(form: any): Promise<IGenericImportFinishedData> {
        const url = `${baseURL}/import/text`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapImportAssignmentQueueForm(form, "text")).execute<IGenericImportFinishedData>();
    }

    async importRequestVlcPortTemplate(): Promise<IDocument> {
        const url = `${baseURL}/valenciaport/import/template`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IDocument>();
    }

    async getVlcPortHistoricPositioningRequestList(filter: any): Promise<IVlcportHistoricList[]> {
        const url = `${baseURL}/valenciaport/positioningrequests`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filter).execute<IVlcportHistoricList[]>();
    }

    async postReprocessVlcPortHistoricPositioningRequestList(fileName: string): Promise<boolean> {
        const url = `${baseURL}/valenciaport/positioningrequest/reprocess`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody({ fileName: fileName }).execute<boolean>();
    }
    async getVlcPortHistoricInspectionsAppointmentsList(filter: any): Promise<IVlcportHistoricList[]> {
        const url = `${baseURL}/valenciaport/inspectionsappointments`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filter).execute<IVlcportHistoricList[]>();
    }

    async getVlcPortHistoricInspectionsResultsList(filter: any): Promise<IVlcportHistoricList[]> {
        const url = `${baseURL}/valenciaport/inspectionresults`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filter).execute<IVlcportHistoricList[]>();
    }

    async getRequestAppointmentData(requestId: string): Promise<IAppointmentData> {
        const url = `${baseURL}/${requestId}/appointment`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IAppointmentData>();
    }

    async getInspectionCardLocks(requestId: string): Promise<{ isLock: boolean; message: string }> {
        const url = `${process.env.REACT_APP_API_URL}/inspectioncards/${requestId}/locks`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<{ isLock: boolean; message: string }>();
    }
}

const mapImportAssignmentQueueForm = (form: IAssignmentQueueImportForm, param: string) => {
    const formData = new FormData();
    formData.append(param, form.document);
    formData.append("type", form.typeCode);

    return formData;
};

const mapImportRequestVlcPortForm = (excelFile?: any, xml?: string) => {
    const formData = new FormData();
    xml && formData.append("xml", xml);
    excelFile && formData.append("excelFile", excelFile);

    return formData;
};

const mapToEditRequest = (body: any): IEditRequest => {
    return {
        ...body,
        date: new Date(body.date),
        entryOn: body.entryOn ? new Date(convertToLocal(body.entryOn, FormatDate.FULL)) : null,
        dockAssignmentOn: body.dockAssignmentOn ? new Date(convertToLocal(body.dockAssignmentOn, FormatDate.FULL)) : null,
        dockStartOn: body.dockStartOn ? new Date(convertToLocal(body.dockStartOn, FormatDate.FULL)) : null,
        dockEndOn: body.dockEndOn ? new Date(convertToLocal(body.dockEndOn, FormatDate.FULL)) : null,
        exitOn: body.exitOn ? new Date(convertToLocal(body.exitOn, FormatDate.FULL)) : null,
        customsAuthorityArrivalOn: body.customsAuthorityArrivalOn ? new Date(convertToLocal(body.customsAuthorityArrivalOn, FormatDate.FULL)) : null,
        readyForInstructionOn: body.readyForInstructionOn ? new Date(convertToLocal(body.readyForInstructionOn, FormatDate.FULL)) : null,
        representativeArrivalOn: body.representativeArrivalOn ? new Date(convertToLocal(body.representativeArrivalOn, FormatDate.FULL)) : null,
        invoiceCustomerId: body.invoiceCustomerId ? parseInt(body.invoiceCustomerId) : null,
    };
};

const mapToRequestStateHistory = (body: IRequestStateHistory[]): IRequestStateHistory[] => {
    body.forEach((x) => {
        x.createdOn = new Date(convertToLocal(x.createdOn, FormatDate.FULL));
        x.date = new Date(convertToLocal(x.date, FormatDate.FULL));
    });
    return body;
};

const mapToInspections = (body: IInspection[]): IInspection[] => {
    body.forEach((x) => (x.inspectorArrivedOn = new Date(convertToLocal(x.inspectorArrivedOn!, FormatDate.FULL))));
    return body;
};

const mapToAPITransportUnits = (utis: ITransportUnitData[]): any => {
    return utis.map((uti) => {
        return {
            ...uti,
            seals: uti.seals.map((seal) => seal),
            grossWeight: uti.grossWeight && Number(uti.grossWeight),
            packages: uti.packages && Number(uti.packages),
        };
    });
};
