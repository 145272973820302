import { TooltipHost } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../assets/icons";
import { ActuationTypes } from "../../../../../../common/enum/request/ActuationTypes";
import { ActuationTypesGroup } from "../../../../../../common/enum/request/ActuationTypesGroup";
import { useRoleType } from "../../../../../../hooks/role/useRoleType";
import { IRequestInspection, IRequestOrganism } from "../../../../../../models/requests/IRequestDetail";
import { useAppSelector } from "../../../../../../redux/hooks";
import { getCustomerRoles, getPCFUsersRoles } from "../../../../../../services/user/roleService";
import { colors } from "../../../../../../styles/romeu/colors";
import { generateRandomKeys } from "../../../../../../utils/keys";
import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { TeachingBubbleComponent } from "../../../../../common/teachingBubble/teachingBubble";
import { RequestPartialsStyle } from "./requestPartialsStyle.jss";
interface IRequestLetters {
    letter: string;
    visible: boolean;
    message: string;
    classStyle: string;
}
interface IRequestTurnLetter {
    letter?: "M" | "T" | "I";
    visible: boolean;
    classStyle: string;
    appointmentAssigned?: boolean;
}

interface IRequestInspectionPartial {
    requestInspections?: IRequestInspection[];
    showActuationGroup?: boolean;
    styles: IRequestStyles;
    hiddenActuations?: boolean;
}

interface IRequestOrganismPartial {
    requestOrganisms?: IRequestOrganism[];
}

interface IRequestStyles {
    organismCode: string;
    expedient: string;
    header: string;
    sort: string;
    buttonIcon: any;
    icon: string;
    rowContainer: string;
    iconSort: string;
    iconSorted: string;
    sortColumn: string;
    viewMore: string;
    line: string;
    buttonRed: string;
    buttonGreen: string;
    buttonBlue: string;
    buttonOrange: string;
}

export const Letters = (props: IRequestLetters): JSX.Element => {
    return (
        <>
            {props.visible ? (
                <TooltipHost content={props.message}>
                    <div className={props.classStyle}>{props.letter}</div>
                </TooltipHost>
            ) : null}
        </>
    );
};

export const LetterTurnDescription = (props: IRequestTurnLetter): JSX.Element => {
    const { data } = useAppSelector((store) => store.catalog.turns);
    const { t } = useTranslation();
    const turnRequest = data?.find((turn) => turn.code === props.letter);
    if (turnRequest) {
        return (
            <>
                {props.visible ? (
                    <TooltipHost
                        content={`${props.appointmentAssigned ? `${t("LIST.APPOINTMENT")} - ` : t("LIST.NAME.TURN_CODE")} ${
                            turnRequest?.description
                        } `}
                    >
                        <div className={props.classStyle}>({props.letter})</div>
                    </TooltipHost>
                ) : null}
            </>
        );
    }
    return <></>;
};

export const RequestOrganisms = (props: IRequestOrganismPartial): JSX.Element => {
    const organisms = props.requestOrganisms || [];
    return (
        <>
            {organisms?.map((organism: IRequestOrganism, index: number) => (
                <React.Fragment key={index}>
                    <TooltipHost
                        content={organism.organismDescription}
                        children={<span>{organism.organismCode}</span>}
                    />
                    {index < organisms.length - 1 ? ", " : ""}
                </React.Fragment>
            ))}
        </>
    );
};

export const RequestInspections = (props: IRequestInspectionPartial): JSX.Element => {
    const isCustomer = useRoleType(getCustomerRoles());
    const isPCFUser = useRoleType(getPCFUsersRoles());

    const theme = useTheme();
    const style = RequestPartialsStyle({ theme });

    const { showAllInspections } = useAppSelector((state) => state.requestlist);

    const [inspectionsVisible, { toggle: toggleRequestInspections }] = useBoolean(false);
    const buttonId = useId("targetButton");
    const showActuationGroup = props.showActuationGroup ?? false;

    const maxInspectionNumber = showAllInspections && props.requestInspections ? props.requestInspections?.length : 2;

    let organismCode = props.requestInspections?.length ? props.requestInspections[0].organismCode : "";
    let count = 0;

    let showNextInspection = (index: number) => {
        if (props.requestInspections && index < props.requestInspections.length) {
            let before = props.requestInspections[index - 1].organismCode;
            let after = props.requestInspections[index].organismCode;
            let list = props.requestInspections.filter((item) => item.organismCode !== before);
            return after === before && !list.length ? true : false;
        }
        return false;
    };

    const renderTooltipHost = (showActuationGroup: boolean, codes: string[], inspection: IRequestInspection) => {
        const showGroup = showActuationGroup && codes.some((code) => inspection.lastActuationGroup === code);
        const showActuation = !showActuationGroup && codes.some((code) => inspection.lastActuation === code);
        const content = showActuationGroup ? inspection.lastActuationGroupDescription : inspection.lastActuationDescription;
        const value = showActuationGroup ? inspection.lastActuationGroup : inspection.lastActuation;
        if (showGroup || showActuation) {
            return (
                <TooltipHost
                    content={content}
                    children={<InspectionDataContainer value={value} />}
                />
            );
        }
        return <InspectionDataContainer value={value} />;
    };

    const showActuations = (inspection: IRequestInspection) => {
        if (isCustomer) {
            const codesForCustomers = [
                ActuationTypesGroup.POSITION,
                ActuationTypesGroup.DOCUMENTAL,
                ActuationTypesGroup.PENDING,
                ActuationTypesGroup.DO_NOT_POSITION,
            ];
            return renderTooltipHost(showActuationGroup, codesForCustomers, inspection);
        }

        if (isPCFUser) {
            const codesForUserPCF = [
                ActuationTypes.PENDING,
                ActuationTypes.INSPECTION,
                ActuationTypes.DOWNLOAD_COMPLETE,
                ActuationTypes.HALLWAY,
                ActuationTypes.DO_NOT_POSITION,
                ActuationTypes.NOT_APPLICABLE,
                ActuationTypes.SEE_SEAL,
            ];
            return renderTooltipHost(showActuationGroup, codesForUserPCF, inspection);
        }

        return <InspectionDataContainer value={showActuationGroup ? inspection.lastActuationGroup : inspection.lastActuation} />;
    };

    return (
        <>
            {props.requestInspections?.map((inspection: IRequestInspection, index: number) => {
                if (
                    count < maxInspectionNumber &&
                    (count === 0 || showNextInspection(index) || inspection.organismCode !== organismCode || showAllInspections)
                ) {
                    organismCode = inspection.organismCode;
                    count++;
                    return (
                        <div
                            className={style.container}
                            key={`-${generateRandomKeys()}`}
                        >
                            {inspection.organismCode ? (
                                <TooltipHost content={inspection.organismDescription}>
                                    <div className={style.letter}>{inspection.organismCode}</div>
                                </TooltipHost>
                            ) : null}
                            {!props.hiddenActuations && showActuations(inspection)}
                            {inspection.certificateNumber ? <div title={inspection.certificateNumber}>{inspection.certificateNumber}</div> : null}
                            {inspection.inspectorName ? <div title={inspection.inspectorName}> - {inspection.inspectorName} </div> : null}
                        </div>
                    );
                }
                return null;
            })}

            {props.requestInspections !== undefined && props.requestInspections?.length > maxInspectionNumber ? (
                <>
                    <div
                        className={props.styles.viewMore}
                        onClick={toggleRequestInspections}
                    >
                        <CustomIcons
                            iconName={NameIcons.CHEVRONDOWNICON}
                            id={buttonId}
                        />
                        (+{props.requestInspections?.length - 2})
                    </div>
                    {inspectionsVisible && (
                        <TeachingBubbleComponent
                            children={props.requestInspections?.map((inspection: IRequestInspection, index: number) => {
                                return (
                                    <div
                                        className={style.container}
                                        key={`-${generateRandomKeys()}`}
                                    >
                                        {inspection.organismCode ? <div className={style.letter}>{inspection.organismCode}</div> : null}
                                        <InspectionDataContainer
                                            value={showActuationGroup ? inspection.lastActuationGroup : inspection.lastActuation}
                                        />
                                        {inspection.certificateNumber ? <div>{inspection.certificateNumber}</div> : null}
                                        {inspection.inspectorName ? <div> - {inspection.inspectorName} </div> : null}
                                    </div>
                                );
                            })}
                            onToggle={toggleRequestInspections}
                            id={buttonId}
                        />
                    )}
                </>
            ) : null}
        </>
    );
};

interface IInspectionDataContainer {
    value?: string;
}

export const InspectionDataContainer: React.FC<IInspectionDataContainer> = ({ value }) => {
    const actuationColorType = (value?: string): string => {
        let colorText = "transparent";
        if (value === ActuationTypes.PENDING) {
            colorText = colors.palette.orange;
        } else if (
            value === ActuationTypes.DO_NOT_POSITION ||
            value === ActuationTypes.NOT_APPLICABLE ||
            value === ActuationTypesGroup.DO_NOT_POSITION
        ) {
            colorText = colors.palette.blueLight;
        } else if (
            value === ActuationTypes.INSPECTION ||
            value === ActuationTypesGroup.POSITION ||
            value === ActuationTypes.INSPECTION ||
            value === ActuationTypes.HALLWAY ||
            value === ActuationTypes.DOWNLOAD_COMPLETE ||
            value === ActuationTypes.SEE_SEAL
        ) {
            colorText = colors.palette.green;
        }

        return colorText;
    };

    const color = actuationColorType(value);

    const theme = useTheme();
    const style = RequestPartialsStyle({ theme, color });

    return <span className={`${style.actuation} ${!value ? style.notSelect : ""}`}>{`(${value ? value : ActuationTypes.DO_NOT_POSITION})`}</span>;
};
